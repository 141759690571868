import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { purpleMain, darkGradient, bodyColor } from "theme/constants";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { alpha, ListItemButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "@mui/material/styles";

const pages = [
	{
		title: "Home",
		link: "/",
	},
	{
		title: "Services",
		link: "/services",
		isMenu: true,
		menuOptions: [
			{ option: "Automated AI Solutions", link: "/automated-ai-solutions" },
			{ option: "Private LLM Deployment", link: "/private-deployment" },
			{ option: "AI Consulting Strategies", link: "/strategy-consulting" },
			{
				option: "End-to-End AI Architecture",
				link: "/end-to-end-architecture",
			},
			{ option: "Collaboration with teams", link: "/collaboration" },
			{ option: "AI Product Development", link: "/product-development" },
			{ option: "Data Analysis & Insights", link: "/data-analysis" },
			{ option: "Experienced AI Engineers", link: "/AiEngineers" },
		],
	},
	{
		title: "Projects",
		link: "/projects",
	},

	{
		title: "Meet the team",
		link: "/#team",
	},
	{
		title: "Contact",
		link: "/contact",
		cta: true,
	},
];

function ResponsiveAppBar() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [anchorElNav, setAnchorElNav] = useState(null);
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 38,
	});
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [currentMenu, setCurrentMenu] = useState(null);

	const handleMenuOpen = (event, menuOptions) => {
		setAnchorEl(event.currentTarget);
		setCurrentMenu(menuOptions);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setCurrentMenu(null);
		setDrawerOpen(false);
	};

	const toggleDrawer = (open) => () => {
		setDrawerOpen(open);
	};
	const handleMenuClick = (link) => {
		navigate(link);
		handleMenuClose();
	};
	const theme = useTheme();
	return (
		<AppBar
			position="sticky"
			sx={{
				top: 0,
				background: darkGradient,
				zIndex: 1040,

				py: 2,
				px: 6,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			}}
			elevation={trigger ? 1 : 0}
		>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h4"
						noWrap
						component="a"
						href="#appBar"
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontFamily: "Inter, sans-serif",
							fontWeight: 900,
							letterSpacing: ".3rem",
							color: "white",
							textDecoration: "none",
							textTransform: "uppercase",
						}}
						onClick={() => (window.location = "/")}
					>
						Pillar1
					</Typography>
					{/* MOBILE NAV BAR MENU >>>>>>>>>>>>>> */}
					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="open drawer"
							aria-controls="drawer-appbar"
							aria-haspopup="true"
							onClick={toggleDrawer(true)}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>

						<Drawer
							anchor="right"
							open={drawerOpen}
							onClose={toggleDrawer(false)}
						>
							<Box
								sx={{
									width: 250,
									height: "100%",
									background: `linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(41, 1, 58) 50%, rgb(0,0,0) 100%)`,
								}}
							>
								<IconButton
									sx={{
										color: "white",
										display: "flex",
										justifyContent: "flex-end",
									}}
									onClick={toggleDrawer(false)}
								>
									<CloseIcon />
								</IconButton>
								<List>
									{pages.map((item, i) =>
										item.isMenu ? (
											<Box
												key={i}
												sx={{ px: 2 }}
											>
												<Typography
													variant="subtitle1"
													sx={{ color: "white", fontWeight: 700, py: 2 }}
												>
													{item.title}
												</Typography>
												{item.menuOptions.map((menuItem, j) => (
													<ListItemButton
														key={j}
														onClick={() => handleMenuClick(menuItem.link)}
														sx={{ color: "white" }}
													>
														<ListItemText primary={menuItem.option} />
													</ListItemButton>
												))}
											</Box>
										) : (
											<Link
												key={i}
												to={item.link}
												style={{
													textDecoration: "none",
													color: "white",
												}}
											>
												<ListItemButton sx={{ color: "white" }}>
													<ListItemText primary={item.title} />
												</ListItemButton>
											</Link>
										)
									)}
								</List>
							</Box>
						</Drawer>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{ display: { xs: "block", md: "none" } }}
						>
							{pages.map((page) => (
								<MenuItem
									key={page.title}
									onClick={handleCloseNavMenu}
								>
									<Typography sx={{ textAlign: "center" }}>
										{page.title}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>

					{/* END OF MOBILE NAV MENU >>>>>>>>>>>>>>>> */}

					<Typography
						variant="h4"
						noWrap
						component="a"
						href="#aappBar"
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontFamily: "Poppins, sans-serif",
							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
						onClick={() => (window.location = "/")}
					>
						Pillar1
					</Typography>
					<Box
						sx={{
							flexGrow: 1,
							display: {
								xs: "none",
								md: "flex",
								justifyContent: "flex-end",
							},
						}}
					>
						{pages.map((item, i) =>
							item.isMenu ? (
								<Box
									key={i}
									sx={{ mr: 1 }}
								>
									<Button
										sx={{
											color: "white",
											"&:hover": { backgroundColor: "rgba(232, 192, 252, .5)" },
											backgroundColor: currentMenu
												? "rgba(232, 192, 252, .5)"
												: "transparent",
										}}
										onClick={(e) => handleMenuOpen(e, item.menuOptions)}
										endIcon={
											currentMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
										}
									>
										{item.title}
									</Button>
									<Menu
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										open={Boolean(anchorEl && currentMenu === item.menuOptions)}
										onClose={handleMenuClose}
										MenuListProps={{
											"aria-labelledby": "basic-button",
										}}
										sx={{ borderRadius: 1 }}
									>
										{item.menuOptions.map((menuItem, j) => (
											<MenuItem
												key={j}
												onClick={() => handleMenuClick(menuItem.link)}
												sx={{
													"&:hover": {
														backgroundColor: alpha(purpleMain, 0.1),
													},
													py: 1,
													px: 4,
													color: bodyColor,
													mx: 2,
													borderRadius: 1,
												}}
											>
												{menuItem.option}
											</MenuItem>
										))}
									</Menu>
								</Box>
							) : (
								<Link
									key={i}
									to={item.link}
									style={{
										textDecoration: "none",
										color: "white",
									}}
								>
									<Button
										sx={{
											color: "white",
											mr: 1,
											"&:hover": { backgroundColor: "rgba(232, 192, 252, .5)" },
											backgroundColor: item.cta
												? theme.palette.secondary.main
												: "transparent",
										}}
									>
										{item.title}
									</Button>
								</Link>
							)
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default ResponsiveAppBar;
