import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Container from "../../components/Container";
import { Footer, NavBar } from "./components";

const Main = ({
	children,
	colorInvert = false,
	bgcolor = "transparent",
	showLogin,
	showFooter = true,
}) => {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
			<NavBar
				bgcolor={bgcolor}
				showLogin={showLogin}
			/>
			<Box >
				{<main>{children}</main>}
			</Box>
			<Box sx={{ flexGrow: 1 }}></Box>

			<Container paddingY={4}>
				<Footer />
			</Container>
		</Box>
	);
};

Main.propTypes = {
	children: PropTypes.node,
	colorInvert: PropTypes.bool,
	bgcolor: PropTypes.string,
};

export default Main;
