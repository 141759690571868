import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { bodyColor, titleColor } from "theme/constants";

const InfoListSection = ({ title, caption, items, image }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				gap: 4,
				alignItems: "flex-start",
				color: bodyColor,
				px: 4,
				py: 8,
			}}
		>
			{/* Content Section */}
			<Box
				sx={{
					flex: 1,
					paddingLeft: 6,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
                    alignItems: "center",
				}}
			>
				{/* Title */}
				{title && (
					<Typography
						variant="h4"
						sx={{
							fontWeight: "bold",
							color: titleColor,
							mb: 2,
						}}
					>
						{title}
					</Typography>
				)}
				{/* Caption */}
				{caption && (
					<Typography
						sx={{
							mb: 3,
							color: "text.secondary",
						}}
					>
						{caption}
					</Typography>
				)}
				{/* List of Items */}
				<Box>
					{items?.map((item, index) => (
						<Box
							key={index}
							sx={{ mb: 2, ml: 4 }}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: "bold",
								}}
							>
								{item.keyword}
							</Typography>
							<Typography
								variant="body1"
								sx={{
									color: "text.secondary",
									ml: 2,
								}}
							>
								{item.description}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>

			{/* Image Section */}
			{image && (
				<Box
					sx={{
						flex: { xs: "0 0 100%", md: "0 0 50%" },
						display: { xs: "none", md: "flex" },
						justifyContent: "center",
					}}
				>
					<Box
						component="img"
						src={image}
						alt="Illustration"
						sx={{
							maxWidth: "100%",
							maxHeight: 500,
							objectFit: "contain",
							borderRadius: 2,
							boxShadow: 2,
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

InfoListSection.propTypes = {
	title: PropTypes.string,
	caption: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			keyword: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		})
	).isRequired,
	image: PropTypes.string,
};

InfoListSection.defaultProps = {
	title: "",
	caption: "",
	image: null,
};

export default InfoListSection;
