import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import avatarAyman from "images/ayman.jpg";
import avatarPierre from "images/pierre.jpeg";
import avatarHadi from "images/hadi.jpg";
import avatarJorge from "images/jorge.jpg";
import avatarZoe from "images/zoe.jpeg";
import avatarHolden from "images/holden.png";

import Container from "components/Container";
import { lightGradient, titleColor, bodyColor } from "theme/constants";
import { Grow } from "@mui/material";
import { useState, useEffect, useRef } from "react";

const mock = [
	{
		name: "Pierre Arbajian, PhD",
		title: "Chief AI Architect",
		avatar: avatarPierre,
		about:
			"Pierre is our Senior Data Scientist and Chief AI Architect, bringing a wealth of experience in all things data and AI. He is passionate about studying and tinkering with LLMs, recognizing that AI holds the potential to revolutionize the world, he strives to make Pillar1 a relevant player in helping clients effectively navigate and adopt this transformative technology.",
	},
	{
		name: "Ayman Hajja, PhD",
		title: "Chief Technology Officer",
		avatar: avatarAyman,
		about:
			"Serves as our Chief Technology Officer, known for developing and deploying cutting-edge, highly complex solutions. With profound expertise in both theory and practice, Ayman leads implementations with precision and unwavering commitment to quality. He firmly believes in a methodical and careful approach, emphasizing thorough development, testing, and implementation with insightful precision for every project and product.",
	},

	{
		name: "Zoe Duah Yuehua ",
		title: "Machine Learning Engineer",
		avatar: avatarZoe,
		about:
			"Yuehua (Zoe) Duan is a skilled Machine Learning Engineer and Data Scientist with a Ph.D. in Computer Science from the University of North Carolina at Charlotte. She has extensive experience in AI, big data, and software development, contributing to impactful projects at SAS, Weibo.com, and Hanvon Technology. Currently, she serves as a Data Science Consultant at Pillar1, applying her expertise in machine learning, NLP, and predictive analytics to deliver innovative solutions. An accomplished researcher with multiple publications and a patent, Zoe is fluent in English, Chinese, and Japanese, and is recognized for her technical excellence and leadership skills.",
	},
	{
		name: "Jorge Monteagudo",
		title: "Front End Engineer, UI/UX Specialist",
		avatar: avatarJorge,
		about:
			`Jorge Monteagudo is a Front-End Engineer and UI/UX specialist with a strong foundation 
			in web development and cutting-edge frameworks like React, Next.js, and Node.js.
			With a degree in Biology and Chemistry from UNC Chapel Hill, Jorge transitioned from the sciences 
			to pursue his passion for technology, honing his expertise in AIs, APIs, REST frameworks, and dynamic user interfaces. 
			Originally from Cuba, Jorge brings a diverse perspective to the team, combining cultural adaptability with 
			a deep understanding of problem-solving and innovation. Over three years of experience, 
			he has excelled at optimizing performance, troubleshooting challenges, and delivering intuitive 
			solutions that meet client needs. `,
	},
	{
		name: "Hadi Ghattas",
		title: "Back End Engineer",
		avatar: avatarHadi,
		about:
			"Hadi is our skilled Backend Engineer, responsible for designing and maintaining the server-side logic and ensuring seamless integration with frontend components. With deep expertise in various backend technologies, databases, and APIs, Hadi focuses on building robust and scalable solutions. He is dedicated to optimizing system performance and ensuring the reliability and security of our applications, enabling us to deliver high-quality services to our clients.",
	},
	{
		name: "Holden Mcmahan ",
		title: "IT Analyst",
		avatar: avatarHolden,
		about:
			"Holden McMahan is an accomplished IT professional with a strong background in technology solutions, operational management, and team leadership. Currently serving as an IT Analyst at Pillar One LLC, Holden specializes in implementing cutting-edge AI technologies, including Generative AI and Large Language Models, to drive innovation and efficiency. With expertise in hardware and network setup, user research, and rapid problem-solving, Holden plays a pivotal role in enhancing client experiences and meeting technical challenges. Prior to joining Pillar One, Holden gained extensive experience in inventory management at Whole Foods and operational leadership as a General Manager at Flavors West LLC, where they consistently delivered exceptional results, from achieving top sales rankings to successfully leading large teams. Holden combines technical expertise, strategic thinking, and a dedication to excellence to help businesses grow and adapt in an ever-evolving digital landscape.",
	},
];

const WithSimpleCards = () => {
	const theme = useTheme();
	const ref = useRef(null);
	const [isVisible, setVisible] = useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setVisible(true);
				}
			});
		});
		observer.observe(ref.current);
	}, []);
	return (
		<Container id="team">
			<Box>
				<Box marginBottom={4}>
					<Typography
						sx={{
							textTransform: "uppercase",
							WebkitTextStroke: `2px ${theme.palette.secondary.main}  `, // Adds an outline for extra weight
							textFillColor: theme.palette.secondary.main, // Fills the text
							letterSpacing: `${theme.spacing(1 / 4)}`, // Adds some letter spacing
						}}
						variant="h4"
						gutterBottom
						color={theme.palette.secondary.main}
						align={"center"}
						fontWeight={700}
					>
						Our team
					</Typography>
					<Typography
						fontWeight={700}
						variant={"h4"}
						align={"center"}
						color="white"
					>
						Trust the professionals
					</Typography>
				</Box>
				<Grid
					container
					spacing={2}
					alignItems="stretch"
					ref={ref}
				>
					{mock.map((item, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							key={i}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<Grow
								in={isVisible}
								style={{ transitionDelay: `${i * 200}ms` }}
								easing={"ease"}
								timeout={1000}
							>
								<Box
									component={Card}
									boxShadow={2}
									sx={{
										textDecoration: "none",
										transition: "all .2s ease-in-out",
										"&:hover": {
											transform: `translateY(-${theme.spacing(1 / 2)})`,
										},
										height: "100%",
										background: lightGradient,
										borderRadius: "2px solid rgb(245 245 245 / 0%)",
									}}
								>
									<CardContent>
										<Box
											component={Avatar}
											src={item.avatar}
											margin={"0 auto"}
											height={80}
											width={80}
										/>
										<Box
											marginTop={2}
											display="flex"
											flexDirection={"column"}
											alignItems="center"
										>
											<ListItemText
												primary={item.name}
												secondary={item.title}
												primaryTypographyProps={{
													sx: {
														color: titleColor,
														fontSize: "1.25rem",
														fontWeight: 800,
														textAlign: "center",
													},
												}}
												secondaryTypographyProps={{
													sx: {
														color: bodyColor, // Example for secondary text
														fontSize: "1rem",
														textAlign: "center",
														
													},
												}}
											/>
											<Typography
												variant={"subtitle2"}
												color={"text.secondary"}
												mt={1}
											>
												{item.about}
											</Typography>
										</Box>
									</CardContent>
								</Box>
							</Grow>
						</Grid>
					))}
				</Grid>
			</Box>
		</Container>
	);
};

export default WithSimpleCards;
