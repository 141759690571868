import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/20.jpg";
import {
	ReasonsToChooseSection,
	InfoListSection,
	CarouselInfoSection,
} from "pages/components";
import sectionImg from "images/aiPhotos/24.jpg";
import carouselCard1 from "images/aiPhotos/25.jpg";
import carouselCard2 from "images/aiPhotos/26.jpg";
import carouselCard3 from "images/aiPhotos/27.jpg";
import carouselCard4 from "images/aiPhotos/28.jpg";
import carouselCard5 from "images/aiPhotos/29.jpg";
import carouselCard6 from "images/aiPhotos/30.jpg";

const heroContent = {
	title: "End-to-End AI Architecture",
	subtitle: "Empower Your Organization with an AI-First Mindset",
	content: `Building an AI-driven culture is essential for unlocking the full potential of artificial intelligence within your organization. At Pillars AI, we specialize in cultivating an end-to-end AI culture—ensuring your team, processes, and technology work together seamlessly to embrace innovation and drive growth.`,
	bgImage: heroBackgroundImage,
};

const reasonsToChooseContent = {
	title: "Why Pillars AI?",

	reasons: [
		{
			title: "Holistic Approach",
			subtitle:
				"From people to processes, we address every aspect of AI adoption.",
		},
		{
			title: "Industry Expertise",
			subtitle: "Tailored strategies for your sector’s unique needs.",
		},
		{
			title: "Sustainable Impact",
			subtitle: "Lay a foundation for long-term success with AI.",
		},
	],
	bgImage: heroBackgroundImage,
};
const whatIsEndToEndArchitechture = {
	title: "What is End-to-End AI Culture?",
	caption: `An AI-driven culture goes beyond technology. It’s about integrating AI into the fabric of your organization by:`,
	items: [
		{
			keyword: "Fostering AI Literacy",
			description:
				"Empowering teams with knowledge and skills to leverage AI effectively.",
		},
		{
			keyword: "Streamlining Workflows",
			description:
				"Embedding AI into everyday operations for enhanced efficiency.",
		},
		{
			keyword: "Driving Collaboration",
			description: "Aligning departments around shared AI goals and insights.",
		},
		{
			keyword: "Enabling Innovation",
			description:
				"Creating a mindset of continuous improvement and adaptability.",
		},
	],
	image: sectionImg,
};
const howWeBuildAICulture = {
	title: "How We Build AI Culture",
	items: [
		{
			keyword: "AI Awareness Workshops",
			description:
				"Educate teams at all levels about AI’s potential and applications.",
        image: carouselCard1
		},
		{
			keyword: "Upskilling & Training",
			description:
				"Equip employees with the tools and skills to integrate AI into their roles.",
        image: carouselCard2,
		},
		{
			keyword: "Leadership Alignment",
			description: "Guide executives in defining and championing an AI vision.",
        image: carouselCard3,
		},
		{
			keyword: "Change Management",
			description:
				"Ensure smooth adoption of AI by addressing challenges and fostering buy-in.",
        image: carouselCard4,
		},
		{
			keyword: "Operational Integration",
			description:
				"Embed AI into workflows, decision-making, and strategies across departments.",
        image: carouselCard5,
		},
		{
			keyword: "Scalable Frameworks",
			description:
				"Build adaptable structures that grow with your organization’s AI capabilities.",
        image: carouselCard6,
		},
	],
};

const outcomesSectionContent = {
  title: "Outcomes of an AI-Drive Culture",
  items: [
    {keyword: 'Enhanced efficiency and productivity'},
    {keyword: 'Data-driven decision-making at all levels'},
    {keyword: 'Improved employee engagement and innovation'},
    {keyword: 'Scalable AI adoption across the roganization'}
  ], image: carouselCard5
}
const EndToEndArchitechturePage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<InfoListSection {...whatIsEndToEndArchitechture} />
      <CarouselInfoSection {...howWeBuildAICulture} />
			<ReasonsToChooseSection {...reasonsToChooseContent} />
      <InfoListSection {...outcomesSectionContent} />
		</div>
	);
};
export default EndToEndArchitechturePage;
