import { useEffect, useRef, useState } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	Card,
	Avatar,
	Grow,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { titleColor, bodyColor } from "theme/constants";

const KeyFeatureCards = ({ data }) => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);
	return (
		<Box>
			<Container
				display="flex"
				flexDirection="column"
				alignContent="center"
				justifyContent="center"
			>
				<Grid
					container
					spacing={2}
					py={8}
				>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent={"center"}
					>
						<Typography
							sx={{
								textAlign: "center",
								color: titleColor,
								fontWeight: 800,
								fontSize: { xs: "1.25rem", sm: "1.5rem" },
							}}
						>
							{" "}
							Key Features
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						ref={ref}
						sx={{ mt: 0 }}
					></Grid>
					{data.map((item, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={{ span: 4, offset: 0 }}
							key={i}
						>
							<Grow
								in={isVisible}
								style={{
									transformOrigin: "0 0 0",
									transitionDelay: `${i * 150}ms`,
								}}
								timeout={500}
							>
								<Box
									display={"block"}
									width={1}
									height={1}
									sx={{
										textDecoration: "none",

										transition: "all .2s ease-in-out",
										"&:hover": {
											transform: "translateY(-4px)",
										},
									}}
								>
									<Box
										component={Card}
										padding={4}
										width={1}
										height={1}
										sx={{
											background:
												"linear-gradient(180deg, rgba(248, 236, 253, 0.41) 50% ,rgb(245, 245, 245) 100%);",
										}}
									>
										<Box
											display={"flex"}
											flexDirection={"column"}
										>
											{item.icon && (
												<Box
													component={Avatar}
													width={60}
													height={60}
													marginBottom={2}
													bgcolor={alpha(item.color, 0.1)}
													color={item.color}
													variant={"rounded"}
													mx={"auto"}
												>
													{item.icon}
												</Box>
											)}
											<Typography
												variant={"h6"}
												gutterBottom
												sx={{
													fontWeight: 700,
													mx: "auto",
													textAlign: "center",
													color: titleColor,
												}}
											>
												{item.title}
											</Typography>
											<Typography
												color={bodyColor}
												textAlign={"center"}
											>
												{item.subtitle}
											</Typography>
											<Box sx={{ flexGrow: 1 }} />
											<Box
												marginTop={2}
												display={"flex"}
												justifyContent={"flex-end"}
											></Box>
										</Box>
									</Box>
								</Box>
							</Grow>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};
export default KeyFeatureCards;
