import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import { LinkedIn } from "@mui/icons-material";

const Footer = () => {
	const theme = useTheme();
	const { mode } = theme.palette;

	useEffect(() => {}, []);

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					width={1}
					flexDirection={{ xs: "column", sm: "row" }}
				>
					<Typography
						variant="h6"
						style={{
							display: "flex",
							cursor: "pointer",
							color: theme.palette.primary.main,
							fontWeight: 700,
							fontFamily: "Poppins, sans-serif",
							textAlign: "center",
						}}
						onClick={() => (window.location = "/")}
					>
						Pillar1
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				id="socials"
			>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					width={1}
				>
					<IconButton
						aria-label="twitter"
						style={{ color: mode === "light" ? "#000" : "#fff" }}
					>
						<TwitterIcon />
					</IconButton>
					<IconButton
						aria-label="github"
						style={{ color: mode === "light" ? "#000" : "#fff" }}
					>
						<GitHubIcon />
					</IconButton>

					<IconButton
						aria-label="linkedin"
						style={{ color: mode === "light" ? "#000" : "#fff" }}
					>
						<LinkedIn />
					</IconButton>
				</Box>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Typography
					align={"center"}
					variant={"subtitle2"}
					color="text.secondary"
					gutterBottom
				>
					&copy; Pillar1. 2023. All rights reserved
				</Typography>
				<Typography
					align={"center"}
					variant={"caption"}
					color="text.secondary"
					component={"p"}
				>
					When you visit or interact with our sites, services or tools, we or
					our authorized service providers may use cookies for storing
					information to help provide you with a better, faster and safer
					experience and for marketing purposes.
				</Typography>
			</Grid>
		</Grid>
	);
};

export default Footer;
