import { useState, useEffect } from "react";
import {
	Box,
	Button,
	Typography,
	useTheme,
	Grid,
	TextField,
} from "@mui/material";
import heroBackgroundImage from "images/aiPhotos/9.jpg";
import emailjs from "emailjs-com";

const ServiceID = "service_6z495vr";
const templateID = "template_pta4j6d";
const userID = "eu5oNFR-km4D26Cwp";

const ContactForm = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [company, setCompany] = useState("");
	const [websiteURL, setWebsiteURL] = useState("");
	const [message, setMessage] = useState("");

	const resetForm = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPhoneNumber("");
		setCompany("");
		setWebsiteURL("");
		setMessage("");
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = {
			firstName,
			lastName,
			email,
			phoneNumber,
			company,
			websiteURL,
			message,
		};
		emailjs
			.send(
				ServiceID, // Replace with your EmailJS Service ID
				templateID, // Replace with your EmailJS Template ID
				{
					firstName: formData.firstName + " " + formData.lastName,
					email: formData.email,
					phoneNumber: formData.phoneNumber,
					company: formData.company,
					websiteURL: formData.websiteURL,
					message: formData.message,
				},
				userID // Replace with your EmailJS User ID
			)
			.then(
				(response) => {
					setSuccess(true);
					resetForm();
				},
				(err) => {
					console.error("FAILED...", err);
					setSuccess(false);
				}
			)
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		switch (name) {
			case "firstName":
				setFirstName(value);
				break;
			case "lastName":
				setLastName(value);
				break;
			case "email":
				setEmail(value);
				break;
			case "phoneNumber":
				setPhoneNumber(value);
				break;
			case "company":
				setCompany(value);
				break;
			case "websiteURL":
				setWebsiteURL(value);
				break;
			case "message":
				setMessage(value);
				break;
			default:
				break;
		}
	};

	return (
		<Box
			sx={{
				p: 4,
				display: "flex",
				flexDirection: "column",
				gap: 3,
				backgroundColor: "rgba(255, 255, 255, 0.36)",
			}}
		>
			<Box
				display="flex"
				flexDirection={{ xs: "column", md: "row" }}
				gap={2}
				flexGrow
			>
				<TextField
					type="text"
					variant="filled"
					placeholder="First Name"
					name="firstName"
					onChange={handleChange}
					value={firstName}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",

						color: "primary",
						width: "100%",
					}}
				/>
				<TextField
					type="text"
					variant="filled"
					placeholder="Last Name"
					name="lastName"
					onChange={handleChange}
					value={lastName}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",

						color: "primary",
						width: "100%",
					}}
				/>
			</Box>
			<Box
				display="flex"
				flexDirection={{ xs: "column", md: "row" }}
				gap={2}
			>
				<TextField
					type="email"
					variant="filled"
					placeholder="Email"
					name="email"
					onChange={handleChange}
					value={email}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",
						border: 0,

						color: "primary",
						width: "100%",
					}}
				/>
				<TextField
					type="text"
					variant="filled"
					placeholder="Phone Number"
					name="phoneNumber"
					onChange={handleChange}
					value={phoneNumber}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",
						border: 0,
						color: "primary",
						width: "100%",
					}}
				/>
			</Box>
			<Box
				display="flex"
				flexDirection={{ xs: "column", md: "row" }}
				gap={2}
			>
				<TextField
					type="text"
					variant="filled"
					placeholder="Company"
					name="company"
					onChange={handleChange}
					value={company}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",
						border: 0,
						color: "primary",
						width: "100%",
					}}
				/>
				<TextField
					type="text"
					variant="filled"
					placeholder="Website URL"
					name="websiteURL"
					onChange={handleChange}
					value={websiteURL}
					sx={{
						backgroundColor: "rgba(255, 255, 255, 1)",
						border: 0,
						color: "primary",
						width: "100%",
					}}
				/>
			</Box>
			<TextField
				placeholder="Briefly tell us about your project"
				multiline
				variant="filled"
				rows={8}
				name="message"
				onChange={handleChange}
				value={message}
				sx={{
					backgroundColor: "white",
					border: 0,
					color: "primary",
				}}
			/>
			<Button
				type="submit"
				variant="contained"
				color="secondary"
				sx={{ color: "white", fontWeight: 700 }}
				disabled={isSubmitting}
				onClick={handleSubmit}
			>
				{isSubmitting ? "Sending..." : "Send Message"}
			</Button>
			{success && (
				<Typography
					sx={{
						mt: 2,
						color: "rgb(24, 245, 8)",
						textAlign: "center",
						fontSize: "1rem",
					}}
				>
					Message sent! We will get back to you within 24 hours.
				</Typography>
			)}
		</Box>
	);
};

const ContactPage = () => {
	const theme = useTheme();
	// State to trigger animations
	const [animate, setAnimate] = useState(false);

	// Trigger animation when the component is mounted
	useEffect(() => {
		const timer = setTimeout(() => setAnimate(true), 200); // Delay for smooth entry
		return () => clearTimeout(timer);
	}, []);
	return (
		<Grid
			container
			sx={{
				minHeight: "100vh", // Set height for the example
				position: "relative", // Ensure pseudo-element positions correctly
				px: "6rem",
				gap: 1,
				display: "flex",
				boxShadow: "inset 0px 0px 32px 12px rgb(16, 2, 20)",
				overflow: "hidden", // Ensures pseudo-element doesn't spill out
				paddingTop: "5rem",
				"&::before": {
					content: '""', // Pseudo-element content
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${heroBackgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundBlendMode: "multiply",
					filter: "blur(4px)", // Apply blur to the pseudo-element
					zIndex: -1, // Place it behind the children
				},
			}}
		>
			<Grid
				item
				xs={12}
				md={12}
				lg={5}
				mx={"auto"}
				color="white"
				sx={{
					opacity: animate ? 1 : 0, // Fade-in effect
					transform: animate ? "translateY(0)" : "translateY(20px)", // Slide-in from bottom
					transition: "opacity 1s ease, transform 1s ease", // Smooth transition
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					mb: 4,
					gap: 4,
					height: "100%",
				}}
			>
				<h1
					style={{
						opacity: animate ? 1 : 0,
						transform: animate ? "translateY(0)" : "translateY(-20px)",
						transition: "opacity 1s ease, transform 1s ease",
						fontSize: "2.5rem",
						color: theme.palette.secondary.main,
						fontWeight: 900,
						WebkitTextStroke: `2px ${theme.palette.secondary.main}`,
						letterSpacing: "2px",
					}}
				>
					Contact us
				</h1>
				<Typography
					sx={{
						lineHeight: 2,
						opacity: animate ? 1 : 0, // Fade-in effect
						transform: animate ? "translateY(0)" : "translateY(10px)", // Slide-in from bottom
						transition: "opacity 1.5s ease, transform 1.5s ease", // Slightly delayed animation
						fontSize: "1.2rem",
					}}
				>
					To set up a discovery meeting with a member of our team, or for any
					other questions, please fill out the following form and provide a
					brief overview of your project needs.
				</Typography>
				<Typography>
					You can also email us at pillar1@gmail.com and we will get back to you
					within 24 hours.
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				md={12}
				lg={6}
				sx={{ width: "100%", minHeight: "600px", mb: 4 }}
			>
				<ContactForm />
			</Grid>
		</Grid>
	);
};
export default ContactPage;
