import React from "react";
import Box from "@mui/material/Box";
import background from "images/aiPhotos/23.jpg";
const Cover = () => {
	return (
		<Box
			sx={{
				height: { xs: "auto", md: 1 },
				overflow: "hidden",
				position: "relative", // Ensure proper layering
			}}
		>
			{/* Background image with gradient overlay */}
			<Box
				sx={{
					height: { xs: "auto", md: 1 },
					maxHeight: { xs: 300, md: 1 },
					width: 1,
					maxWidth: 1,
					objectFit: "cover",
					backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(54, 3, 77, 0.28)), url(${background})`, // Add gradient overlay
					transform: "scaleX(-1)", // Flip the image
					backgroundPosition: "right", // Adjust position
					backgroundSize: "cover",
				
				}}
			/>
		</Box>
	);
};
export default Cover;
