import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/13.jpg";
import {
	ReasonsToChooseSection,
	KeyFeatureCards,
	HorizontalTimeline,
} from "pages/components";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SchemaIcon from "@mui/icons-material/Schema";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InsightsIcon from "@mui/icons-material/Insights";

const heroContent = {
	title: "AI Consulting Strategy",
	subtitle: "Turn Vision into Action with Expert AI Strategy",
	content: `At Pillars AI, we specialize in helping organizations navigate the complexities of AI adoption. Our AI Consulting Strategy services provide actionable insights and customized roadmaps to integrate AI into your business operations, drive innovation, and deliver measurable results.`,
	bgImage: heroBackgroundImage,
};

const reasonsToChooseContent = {
	title: "Why Pillars AI for Consulting?",
	reasons: [
		{
			title: "Tailored Strategies",
			subtitle:
				"We align AI initiatives with your unique goals, industry, and challenges.",
		},
		{
			title: "Expert Guidance",
			subtitle:
				"Access a team of AI specialists with deep technical expertise and business acumen.",
		},
		{
			title: "End-to-End Support",
			subtitle:
				"From ideation to implementation, we ensure a seamless journey.",
		},
		{
			title: "Future-Proof Solutions",
			subtitle:
				"Stay ahead of trends with strategies designed for scalability and long-term success.",
		},
	],
	bgImage: heroBackgroundImage,
};

const whatWeOffer = [
	{
		title: "AI Readiness Assessment",
		subtitle: `Evaluate your organization's AI maturity and identify opportunities for growth.`,
		color: "rgb(80, 182, 255)",
	},
	{
		title: "Custom Roadmaps",
		subtitle: `Develop clear, actionable plans to integrate AI across operations.`,
		color: "rgb(255, 85, 85)",
	},
	{
		title: "Data Strategy Development",
		subtitle: `Optimize your data for AI success with robust collection, management, and governance strategies.`,
		color: "rgb(225, 211, 17)",
	},
	{
		title: "Proof of Concept (PoC) Development",
		subtitle: `Validate ideas quickly and effectively with tailored PoC models.`,
		color: "rgb(186, 92, 254)",
	},
	{
		title: "Technology Recommendations",
		subtitle: `Identify the best AI tools, platforms, and frameworks for your needs.`,
		color: "rgb(38, 173, 40)",
	},
];

const timelineContent = {
	title: "Our Consulting Process",
	steps: [
		{
			title: "Discovery",
			description: "Understand your business, challenges, and opportunities.",
			icon: <ManageSearchIcon />,
			color: 'rgb(163, 202, 245) ',
		},
		{
			title: "Strategic Planning",
			description: "Define clear objectives and develop a roadmap to success.",
			icon: <SchemaIcon />,
			color: 'rgb(142, 190, 241)',
		},
		{
			title: "Implementation Support",
			description:
				"Collaborate on deployment, ensuring integration with existing worflows.",
			icon: <ContactSupportIcon />,
			color: "rgb(116, 175, 239)",
		},
		{
			title: "Evaluation & Optimization",
			description:
				"Measure outcomes and refine strategies for continuous success.",
			icon: <InsightsIcon />,
			color: "rgba(87, 159, 235)",
		},
	],
};

const StrategyConsultingPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<KeyFeatureCards data={whatWeOffer} />
			<HorizontalTimeline content={timelineContent} />
			<ReasonsToChooseSection {...reasonsToChooseContent} />
			
		</div>
	);
};

export default StrategyConsultingPage;
