import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/22.jpg";
import { ReasonsToChooseSection, KeyFeatureCards, CarouselInfoSection, InfoListSection } from "pages/components";
import carouselImg1 from "images/aiPhotos/31.jpg";
import carouselImg2 from "images/aiPhotos/32.jpg";
import carouselImg3 from "images/aiPhotos/33.jpg";
import carouselImg4 from "images/aiPhotos/34.jpg";
import sectionImg from "images/aiPhotos/21.jpg";

const heroContent = {
	title: "Experienced AI Engineers",
	subtitle: "Partner with Experts to Drive AI Success",
	content: `At Pillars AI, our team of experienced AI engineers brings deep technical expertise and hands-on experience to deliver cutting-edge AI solutions. From development to deployment, we provide the talent and skills needed to achieve your AI goals.`,
	bgImage: heroBackgroundImage,
};
const reasonsToWorkWithOurEngineers = {
	title: "Why Work with Our Engineers?",
	reasons: [
		{
			title: "Proven Expertise",
			subtitle:
				"Specialists in machine learning, natural language processing, computer vision, and more.",
		},
		{
			title: "Tailored Solutions",
			subtitle: "Build AI systems designed to meet your specific needs.",
		},
		{
			title: "End-to-End Support",
			subtitle: "From ideation to optimization, we ensure seamless execution.",
		},
		{
			title: "Scalable Experience",
			subtitle:
				"Expertise in deploying solutions that grow with your business.",
		},
	],
	bgImage: heroBackgroundImage,
};

const whatWeOffer = [
	{
		title: "Custom AI Model Development",
		subtitle: "Build models tailored to your domain.",
		color: "rgb(80, 182, 255)",
	},
	{
		title: "AI Integration Services",
		subtitle: "Embed AI into your existing workflows and platforms.",
		color: "rgb(255, 85, 85)",
	},
	{
		title: "Optimization & Scaling",
		subtitle: "Enhance performance for large-scale deployments.",
		color: "rgb(225, 211, 17)",
	},
	{
		title: "Domain-Specific Expertise",
		subtitle: "Specialized knowledge in healthcare, finance, retail, and more.",
		color: "rgb(186, 92, 254)",
	},
	{
		title: "Ongoing Support",
		subtitle:
			"Regular updates and maintenance to keep your systems performing at their best.",
		color: "rgb(38, 173, 40)",
	},
];

const ourEngineersExcelInContent = {
	title: "Our Engineers Excel In",
	items: [
		{
			keyword: "Machine Learning",
			description:
				"Supervised, unsupervised, reinforcement learning, and more.",
			image: carouselImg1,
		},
		{
			keyword: "Natural Language Processing",
			description: "Text analysis, sentiment analysis, chatbots, and more.",
			image: carouselImg2,
		},
		{
			keyword: "Computer Vision",
			description:
				"Image recognition, object detection, video analysis, and more.",
			image: carouselImg3,
		},

		{
			keyword: "Data Engineering",
			description: "Building Robust piplelines for AI-driven insights",
			image: carouselImg4,
		},
	],
};

const collaborativeApproachSectionContent = {
	title: "Collaborative Approach",
	image:sectionImg,
	items: [
		{
			keyword: "Team Integration",
			description: "Our engineers work seamlessly with your internal teams.",
		},
		{
			keyword: "Agile Methodologies",
			description:
				"Rapid iterations ensure quick deliveryt of imapctful solutions",
		},
		{
			keyword: "Knowledge Sharing",
			description: "Upskill teams to maintain and expand AI systems",
		},
	],
};

const AIEngineersPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<KeyFeatureCards data={whatWeOffer} />
			<CarouselInfoSection {...ourEngineersExcelInContent} />
			<InfoListSection {...collaborativeApproachSectionContent} />
			<ReasonsToChooseSection {...reasonsToWorkWithOurEngineers} />
		</div>
	);
};
export default AIEngineersPage;
