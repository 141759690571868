import { useEffect, useRef, useState } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { Fade } from "@mui/material";

const ReasonsToChooseSection = ({ title, reasons, bgImage }) => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);
	return (
		<Box
			sx={{
				color: "white",
				minHeight: "100vh", // Set height for the example
				position: "relative", // Ensure pseudo-element positions correctly

				display: "flex",
				alignItems: "center",
				boxShadow: "inset 0px 0px 32px 12px rgb(16, 2, 20)",
				overflow: "hidden", // Ensures pseudo-element doesn't spill out
				"&::before": {
					content: '""', // Pseudo-element content
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundBlendMode: "multiply",
					filter: "blur(4px)", // Apply blur to the pseudo-element
					zIndex: -1, // Place it behind the children
				},
			}}
			ref={ref}
		>
			<Container>
				<Grid
					container
					spacing={6}
				>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent={"center"}
                        
						color={theme.palette.secondary.main}
						mb={4}
						mt={{ xs: 4, sm: 0 }}
					>
						<Typography
							variant="h4"
							sx={{ fontWeight: 800, textAlign: "center" }}
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						{reasons.map((reason, i) => (
							<Fade
								in={isVisible}
								timeout={500 + i * 500}
								key={i}
							>
								<Grid
									container
									sx={{ borderBottom: "1px solid rgba(130, 130, 130, .5)" }}
								
								>
									<Grid
										item
										xs={4}
										py={4}
										display="flex"
										justifyContent="center"
										alignItems={"center"}
									>
										<Typography
											textAlign={"center"}
											fontWeight="bold"
											fontSize={{ xs: "1rem", sm: "1.25rem" }}
										>
											{reason.title}
										</Typography>
									</Grid>
									<Grid
										item
										xs={8}
										display="flex"
										justifyContent="center"
										alignItems={"center"}
									>
										<Typography
											textAlign={"center"}
											fontSize="1rem"
										>
											{reason.subtitle}
										</Typography>
									</Grid>
								</Grid>
							</Fade>
						))}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default ReasonsToChooseSection;
