import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/17.jpg";
import { ReasonsToChooseSection, CarouselInfoSection, InfoListSection } from "pages/components";
import carouselCard1 from "images/aiPhotos/25.jpg";
import carouselCard2 from "images/aiPhotos/26.jpg";
import carouselCard3 from "images/aiPhotos/27.jpg";
import carouselCard4 from "images/aiPhotos/28.jpg";
import carouselCard5 from "images/aiPhotos/30.jpg";
import sectionImg from "images/aiPhotos/21.jpg";
import sectionImg2 from "images/aiPhotos/13.jpg";
import { Info } from "@mui/icons-material"

const heroContent = {
	title: "Collaborations with Teams",
	subtitle: "Empower Teams with AI Expertise",
	content: `At Pillars AI, we work alongside your teams to co-create AI solutions that fit your business goals. By blending our AI expertise with your team's knowledge, we build seamless, impactful, and sustainable AI strategies.`,
	bgImage: heroBackgroundImage,
};
const reasonsToCollaborateContent = {
	title: "Why Collaborate with Us?",
	reasons: [
		{
			title: "Expertise + Context",
			subtitle: "Combine our AI knowledge with your industry experience.",
		},
		{
			title: "Customized Support",
			subtitle: "Develop tailored solutions for your unique challenges.",
		},
		{
			title: "Seamless Integration",
			subtitle: "Embed AI into existing workflows and culture.",
		},
		{
			title: "Team Empowerment",
			subtitle: "Equip your workforce to manage AI independently.",
		},
	],
	bgImage: heroBackgroundImage,
};

const howWeWorkTogetherSection  = {
	title: "How We Work Together",
	items: [
		{
			keyword: "Discovery",
			description:
				"Align goals and understand challenges.",
        image: carouselCard1
		},
		{
			keyword: "Workshops",
			description:
				"Bridge departments to define AI use cases",
        image: carouselCard2,
		},
		{
			keyword: "Embedded Experts",
			description: "Collaborate directly with your teams.",
        image: carouselCard3,
		},
		{
			keyword: "Knowledge Transfer",
			description:
				"Train your team for long-term success.",
        image: carouselCard4,
		},
		{
			keyword: "Ongoing support",
			description:
				"Refine and scale solutions as your needs evolve.",
        image: carouselCard5,
		},
		
	],
};

const benefitsOfColaborationSection = {
	title: "Benefits of Collaboration",
	items: [
		{
			keyword: "Faster innovation through shared expertise",
			
		},
		{
			keyword: "Tailored solutions that address real business needs",
			
		},
		{
			keyword: "Stronger team engagement and ownership",
		},
		{
			keyword: "Scalable AI strategies for sustainable growth",
			
		},
	],
	image: sectionImg,
}

const idealScenariosSectionContent = {
	title: "Ideal Scenarios",
	items:[
		{
			keyword: "AI Co-Creation:",
			description: "Develop models and workflows with your team"
		},
		{keyword: "Skill Development:",
			description: "Enhance internal AI expertise"
		},
		{keyword: "Cross-Departmental Alignment:",
			description: "Achieve unified AI goals"
		},
		{keyword:"Pilot Programs:",
			description: "Test and refine AI initiatives"
		}
	], 
	image: sectionImg2
}
const CollaborationPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<InfoListSection {...benefitsOfColaborationSection} />
			<CarouselInfoSection {...howWeWorkTogetherSection} />
			<InfoListSection {...idealScenariosSectionContent} />
			<ReasonsToChooseSection {...reasonsToCollaborateContent} />
		</div>
	);
};
export default CollaborationPage;
