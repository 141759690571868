import {
	Box,
	Typography,
	Button,
	Grow,
	Fade,
} from "@mui/material";
import { Link } from "react-router-dom";
//icon imports
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import LockIcon from "@mui/icons-material/Lock";
import TuneIcon from "@mui/icons-material/Tune";
import StairsIcon from "@mui/icons-material/Stairs";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MergeIcon from "@mui/icons-material/Merge";
//themes

//images
import heroBackgroundImage from "images/aiPhotos/21.jpg";
import reasonsBgImage from "images/aiPhotos/6.jpg";

//react imports
import { useState, useEffect, useRef } from "react";

import { FeaturesHero, ReasonsToChooseSection, KeyFeatureCards} from "pages/components";

const mock = [
	{
		title: "End-to-End Automation",
		subtitle: `Our AI solutions are designed to seamlessly integrate into
		existing workflows, automating repetitive tasks and improving operations across
		departments. From data processing to customer engagement, our systems manage it all.`,
		icon: <ModeOfTravelIcon fontSize="large" />,
		color: "rgb(80, 182, 255)",
	},
	{
		title: "Private and Secure Implementation",
		subtitle: `We deploy private AI models tailored to your business needs, ensuring robust data privacy and compliance with industry regulations. Your data remains yours, protected by advanced encryption and secure protocols.`,
		icon: <LockIcon fontSize="large" />,
		color: "rgb(255, 85, 85)",
	},
	{
		title: "Customizable AI Workflows",
		subtitle: `Every business is unique, and so are our solutions. We collaborate closely with your team to customize AI workflows that align with your specific goals, providing a truly personalized approach.`,
		icon: <TuneIcon fontSize="large" />,
		color: "rgb(225, 211, 17)",
	},
	{
		title: "Scalable Architecture",
		subtitle: `Built with scalability in mind, our AI systems grow alongside your business. Whether you’re a startup or an enterprise, our solutions adapt to meet evolving demands.`,
		icon: <StairsIcon fontSize="large" />,
		color: "rgb(186, 92, 254)",
	},
	{
		title: "Insightful Data Analysis",
		subtitle: `Our AI tools extract actionable insights from your data, empowering you to make informed decisions and stay ahead in competitive markets. From predictive analytics to real-time reporting, we deliver intelligence that matters.`,
		icon: <QueryStatsIcon fontSize="large" />,
		color: "rgb(38, 173, 40)",
	},
	{
		title: "Seamless Integration with Existing Systems",
		subtitle:
			"Pillar1 ensures that our solutions integrate effortlessly with your current infrastructure, reducing downtime and enabling smooth transitions.",
		icon: <MergeIcon fontSize="large" />,
		color: "rgb(248, 140, 85)",
	},
];
const AutomatedSolutionsReasons = [
	{
		title: " Enhanced Efficiency",
		subtitle:
			"Eliminate bottlenecks and increase productivity by automating time-consuming processes.",
	},
	{
		title: " Cost Reduction: ",
		subtitle:
			"Lower operational costs by reducing manual efforts and streamlining workflows.",
	},
	{
		title: " Increased Accuracy:",
		subtitle:
			"Mitigate human error with AI-driven precision in data handling and decision-making.",
	},
	{
		title: "Real-Time Adaptability: ",
		subtitle:
			"Respond quickly to market changes with AI systems that evolve alongside your needs.",
	},
	{
		title: " Expert Support: ",
		subtitle:
			"Our team of AI specialists provides ongoing guidance, training, and maintenance for your solutions.",
	},
];

const AutomatedSolutionsHero = {
	title: "Automated AI Solutions",
	content: `At Pillar1, we specialize in designing and delivering innovative automated AI solutions that transform the way businesses operate. By using the latest advancements in artificial intelligence, we empower organizations to streamline processes, make data-driven decisions, and achieve greater efficiency with minimal human intervention.`,
	bgImage: heroBackgroundImage,
};


const AutomatedAISolutionsPage = () => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);
	return (
		<Box
			display="flex"
			flexDirection="column"
		>
			<FeaturesHero {...AutomatedSolutionsHero} />

			<KeyFeatureCards data={mock}/>

			<ReasonsToChooseSection
				title={"Why choose Pillar1 Automated Solutions?"}
				reasons={AutomatedSolutionsReasons}
				bgImage={reasonsBgImage}
			/>

			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				py={8}
				gap={4}
				ref={ref}
				px={2}
			>
				<Fade
					in={isVisible}
					timeout={1000}
				>
					<Typography
						maxWidth={800}
						textAlign="center"
					>
						At Pillar1, we don’t just implement AI—we reimagine what’s possible
						for your business. Whether you aim to optimize existing workflows,
						enhance customer experiences, or unlock new revenue streams, our
						automated AI solutions are the key to achieving your goals.
					</Typography>
				</Fade>
				<Grow
					in={isVisible}
					timeout={1500}
				>
					<Link
						key={"contact"}
						to={"/contact"}
						style={{
							textDecoration: "none",
							color: "white",
						}}
					>
						<Button
							variant="contained"
							color="secondary"
						>
							INQUIRE NOW
						</Button>
					</Link>
				</Grow>
			</Box>
		</Box>
	);
};
export default AutomatedAISolutionsPage;
