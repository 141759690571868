const purpleMain = "#5A377A";
const purpleWhite = "rgb(238, 230, 244)"
const contrastOrange = "rgb(188, 71, 36)";

const darkGradient = `linear-gradient(90deg, ${purpleMain} 0%, rgb(1, 0, 2) 30%)`;

const titleColor = "#083A5E"
const bodyColor ="#497696"


const lightGradient = "linear-gradient(180deg, rgb(240, 240, 255) 50% ,rgb(245, 245, 245) 100%);";
export { purpleMain, darkGradient, purpleWhite, contrastOrange, lightGradient, bodyColor, titleColor };
