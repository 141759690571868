import { useState, useEffect } from "react";
import {
	Box,
	Grid,
	Typography,
	Button,
	Paper,
	Modal,
	IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import heroBg from "images/aiPhotos//23.jpg";
import project1 from "images/projects/iss/Thumbnail_ISS.jpg";
import project2 from "images/projects/pathfinder/Thumbnail_Pathfinder.jpg";
import { titleColor, purpleMain } from "theme/constants";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import issBg from "images/projects/iss/issBg.jpg";
import pathfinderBg from "images/projects/pathfinder/pathfinderBg.jpg";
import previewPathinder from "images/projects/pathfinder/preview.jpg";
import previewIss from "images/projects/iss/preview.jpg";

const projects = [
	{
		index: 0,
		title: "Pathfinder",
		description: [
			`At Pillar1, we developed the prototype for International Social Service Archive Digitization project, which aimed to build a comprehensive digital archive system that turned dormant social work documents into fully searchable, accessible, and valuable data. By digitizing large volumes of case files, guidelines, and regulatory documents, we streamlined the processes of classification, search, and secure management. Through scanning, splitting, and advanced AI-driven OCR, we established consistent metadata fields and enabled swift retrieval of relevant information. Summaries offered quick overviews of complex documents, drastically reducing the need for manual research and improving organizational efficiency.`,
			`This flexible platform supported the transformation of historical archives into actionable data for policy development, evidence-based interventions, and regional collaboration, the system maximized the value of previously underused information. Over time, it will allow research teams to analyze critical trends and insights across multiple countries and social work domains.`,
			`The project delivered a prototype for a user-friendly interface with advanced data classification and secure document management capabilities for social service professionals and researchers to effectively manage, analyze, and safeguard essential social work information.`,
		],
		thumbnail: project2,
		background: pathfinderBg,
		typography: ["Inter", "Roboto"],
		colors: [
			{ name: "Deep Midnight Blue", hex: "#090E23" },
			{ name: "Tropical Teal", hex: "#12AE9E" },
			{ name: "Forest Teal", hex: "#0F766E" },
		],
		preview: previewPathinder,
	},
	{
		index: 1,
		title: "ISS",
		description: [
			`At Pillar1, we have developed the prototype of an AI-assisted social case management system that re-engineers traditional social work processes for improved speed, quality, and consistency. By integrating domain knowledge with powerful foundation models, our system transforms manual, time-consuming workflows into dynamic, data-driven operations. The system is grounded in user domain specific guidelines for diverse social services—such as child protection, family reunification, and refugee assistance—offering comprehensive support to caseworkers, managers, and organization directors. Through embedded AI capabilities, critical case information is analyzed to identify immediate risks, plan interventions, and coordinate across agencies. The result is streamlined decision-making, better time management, and proactive planning that places client safety and success at the forefront.`,
			`Additionally, all stakeholders gain real-time insights, from case summaries to performance evaluations, ensuring higher team performance and greater process adherence. By centralizing data and leveraging advanced AI analysis, the Pillar1 solution delivers consistent recommendations, fosters user-friendly communication, and guarantees continuity in care. Its feedback loop ensures continuous improvement as insights gleaned from completed cases inform best practices going forward. With Pillar1’s system, organizations are empowered to enhance efficiency, quality, and accountability while achieving optimal client outcomes for social work around the globe. This solution sets new benchmarks in AI-driven social case management excellence.`,
		],
		thumbnail: project1,
		background: issBg,
		typography: ["Inter", "Roboto"],
		colors: [
			{ name: "Steel Blue", hex: "#768894" },
			{ name: "Seafoam Green", hex: "#6FA2A5" },
			{ name: "Deep Midnight Blue", hex: "#090E23" },
		],
		preview: previewIss,
	},
	{
		index: 2,
		title: "Document comprehension",
		description: [
			`At Pillar1, we created an AI-assisted document comprehension system that transforms uploaded PDFs into searchable text through OCR. Users receive alerts when documents are ready, then interact with a language model to annotate and ask questions. By highlighting sections and storing all queries and answers, our platform ensures a robust record of each interaction. A unique URL retains all document context for future reference, streamlining the review of contracts, policies, or legal documents. This comprehensive approach simplifies complex text analysis, empowers users with actionable insights, and maintains a dynamic, accessible archive of knowledge for ongoing document engagement. Interactions are stored.`,
		],
		thumbnail: project1,
		background: issBg,
		typography: ["Inter", "Roboto"],
		colors: [
			{ name: "Steel Blue", hex: "#768894" },
			{ name: "Seafoam Green", hex: "#6FA2A5" },
			{ name: "Deep Midnight Blue", hex: "#090E23" },
		],
		preview: previewIss,
	},
];

const ProjectCard = ({ title, description, thumbnail, handleClick }) => {
	return (
		<Box
			component={Paper}
			onClick={handleClick}
			sx={{
				position: "relative",
				backgroundImage: `url(${thumbnail})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: { xs: 300, md: 350, lg: 250 },
				height: { xs: 300, md: 350, lg: 250 },
				overflow: "hidden",
				cursor: "pointer",
				borderRadius: 0,
				boxShadow: "0px 20px 32px 0px rgba(0, 0, 0, 0.4)",

				//on hover zoom background image

				transition: "transform 0.3s ease",
				"&:hover": {
					transform: "scale(1.05)",
				},
				"&:hover .overlay": {
					opacity: 1,
				},
				"&:hover .title": {
					opacity: 1,
					transform: "translateY(0)",
				},
				"&:hover .line-horizontal": {
					width: "45%",
				},
				"&:hover .line-vertical": {
					height: "45%",
				},
			}}
		>
			{/* Black Overlay */}
			<Box
				className="overlay"
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
					opacity: 0,
					transition: "opacity 0.3s ease",
				}}
			></Box>

			{/* Project Title */}
			<Typography
				className="title"
				sx={{
					position: "absolute",

					transform: "translate(-50%, -50%) translateY(20px)",
					color: "white",
					textAlign: "center",
					width: "100%",
					height: "100%",
					alignContent: "center",
					opacity: 0,
					transition: "opacity 0.3s ease, transform 0.3s ease",
					fontWeight: "bold",
					fontSize: "1.5rem",
					zIndex: 2,
					p: 4,
				}}
			>
				{title}
			</Typography>

			{/* Animated Line: Bottom Left */}
			<Box
				className="line-horizontal"
				sx={{
					position: "absolute",
					top: "10%",
					left: "10%",
					height: "6px",
					width: "0%",
					backgroundColor: "white",
					transition: "width 0.5s ease",
					borderRadius: "0px 0px 14px 0px",
				}}
			></Box>
			<Box
				className="line-vertical"
				sx={{
					position: "absolute",
					top: "10%",
					left: "10%",
					width: "6px",
					height: "0%",
					backgroundColor: "white",
					transition: "height 0.5s ease",
					borderRadius: "0px 0px 14px 0px",
				}}
			></Box>

			{/* Animated Line: Top Right */}
			<Box
				className="line-horizontal"
				sx={{
					position: "absolute",
					bottom: "10%",
					right: "10%",
					height: "6px",
					width: "0%",
					backgroundColor: "white",
					transition: "width 0.5s ease",
					borderRadius: "14px 0px 0px 0px",
				}}
			></Box>
			<Box
				className="line-vertical"
				sx={{
					position: "absolute",
					bottom: "10%",
					right: "10%",
					width: "6px",
					height: "0%",
					backgroundColor: "white",
					transition: "height 0.5s ease",
					borderRadius: "14px 0px 0px 1px",
				}}
			></Box>
		</Box>
	);
};

const ProjectDetailsModal = ({ open, handleClose, project }) => {
	const theme = useTheme();
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					maxHeight: "100vh",
					bgcolor: "background.paper",
					boxShadow: 24,
					border: 0,
					outline: "none",
					overflow: "scroll",
				}}
			>
				{/* Modal Header */}
				<Box
					display="flex"
					justifyContent={"flex-end"}
					gap={3}
					sx={{
						background: `linear-gradient(90deg, ${purpleMain} 0%, rgb(19, 1, 38) 100%)`,
					}}
					color="white"
					p={2}
				>
					<IconButton>
						<ArrowBackIosIcon sx={{ color: "white" }} />
					</IconButton>
					<IconButton>
						<ArrowForwardIosIcon sx={{ color: "white" }} />
					</IconButton>

					<IconButton onClick={handleClose}>
						<CloseIcon sx={{ color: "white" }} />
					</IconButton>
				</Box>
				{/* Modal title section */}
				<Box
					sx={{
						minHeight: 200,
						background: `linear-gradient(135deg, rgba(103, 103, 103, 0.75), rgba(225, 210, 255, 0.75)), url(${project.background})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Typography
						sx={{
							textAlign: "center",
							color: "white",
							fontWeight: "bold",
							px: 2,
							py: 1,
							backgroundColor: theme.palette.primary.main,
							textTransform: "uppercase",
							fontSize: { xs: ".875rem", sm: "1rem" },
							borderRadius: 1,
						}}
					>
						Projects
					</Typography>
					<Typography
						id="simple-modal-title"
						sx={{
							fontSize: {
								xs: "2rem",
								sm: "2.5rem",
								color: "white",
								fontWeight: 800,
							},
						}}
					>
						{project.title}
					</Typography>
				</Box>
				{/* content */}
				<Grid container>
					<Grid
						item
						xs={8}
						sx={{
							padding: 4,
							display: "flex",
							flexDirection: "column",
							gap: 4,
						}}
					>
						{" "}
						{/* projecrt overview */}
						<Typography
							sx={{
								textAlign: "center",
								color: "white",
								fontWeight: "bold",
								px: 2,
								py: 1,
								backgroundColor: theme.palette.primary.main,
								textTransform: "uppercase",
								fontSize: { xs: ".75rem", sm: ".875rem" },
								borderRadius: 1,
								width: "fit-content",
							}}
						>
							Project Overview
						</Typography>
						<Typography
							id="simple-modal-description"
							background="red"
							sx={{ width: "fit-content", color: titleColor }}
						>
							{project.description.map((desc, index) => (
								<Typography
									key={index}
									sx={{ color: titleColor }}
								>
									{desc}
									<br />
									<br />
								</Typography>
							))}
						</Typography>
						{/* --------------------- */}
						<Box
							display="flex"
							justifyContent="space-between"
							gap={2}
							flexDirection={"column"}
						>
							{/* colors */}
							<Typography
								sx={{
									textAlign: "center",
									color: "white",
									fontWeight: "bold",
									px: 2,
									py: 1,
									backgroundColor: theme.palette.primary.main,
									textTransform: "uppercase",
									fontSize: { xs: ".75rem", sm: ".875rem" },
									borderRadius: 1,
									width: "fit-content",
									mb: 4,
								}}
							>
								Colors and Typography{" "}
							</Typography>
							<Box display="flex">
								{project.colors && project.colors.length > 0 ? (
									project.colors.map((color, index) => (
										<Box
											key={index}
											sx={{
												height: 200,
												width: 200,
												backgroundColor: color.hex,
												display: "flex",
												justifyContent: "center",
												alignItems: "flex-start",
												flexDirection: "column",
												color: "white",
												paddingLeft: 4,
											}}
										>
											<Typography
												sx={{ textTransform: "uppercase", fontWeight: "600" }}
											>
												{color.name}
											</Typography>
											<Typography>{color.hex}</Typography>
										</Box>
									))
								) : (
									<Typography>No colors available</Typography>
								)}
							</Box>
							{/* --------- */}
							{/* typography */}
							<Box
								display={"flex"}
								gap={4}
								flexDirection={"column"}
								mt={4}
							>
								{project.typography.map((font) => (
									<Box key={font}>
										<Typography
											sx={{
												fontSize: "1.25rem",
												fontWeight: 700,
												color: titleColor,
											}}
										>
											{font}
										</Typography>
										<Typography sx={{ fontSize: "1.5em", color: titleColor }}>
											A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
											a b c d e f g h i j k l m n o p q r s t u v w x y z
										</Typography>
									</Box>
								))}
							</Box>
							{/* -------- */}
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
						p={4}
						minHeight={400}
					>
						<img
							src={project.preview}
							alt={project.title}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								objectPosition: "center",
								borderRadius: ".75em",
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

const ProjectsPage = () => {
	const theme = useTheme();
	//open project page
	const [openProject, setOpenProject] = useState(false);
	const [selectedProject, setSelectedProject] = useState({
		index: 0,
		title: "",
		description: [],
		thumbnail: "",
		background: "",
		typography: [],
		colors: [],
	});
	const handleClick = (project) => {
		setOpenProject(true);
		setSelectedProject(project);
	};
	useEffect(() => {
	}, [openProject, selectedProject]);

	return (
		<Box>
			<Grid
				container
				sx={{
					color: "white",
					textAlign: "left",
					padding: { xs: "40px 20px", sm: "80px 40px" },
					position: "relative",
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
					alignItems: "space-between",
					justifyContent: "center",
					gap: 2,

					minHeight: "20vh", // Set height for the example
					boxShadow: "inset 0px 0px 32px 12px rgb(16, 2, 20)",
					overflow: "hidden", // Ensures pseudo-element doesn't spill out
					"&::before": {
						content: '""', // Pseudo-element content
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${heroBg})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundAttachment: "fixed",
						backgroundBlendMode: "multiply",
						filter: "blur(4px)", // Apply blur to the pseudo-element
						zIndex: -1, // Place it behind the children
					},
				}}
			>
				<Grid
					item
					xs={12}
					sm={5}
				>
					<Typography
						variant="h2"
						sx={{
							fontSize: { xs: "2rem", sm: "3rem" },
							fontWeight: "bold",
							mb: 2,
						}}
					>
						Empowering Businesses Through Innovation
					</Typography>
					<Typography
						variant="h5"
						sx={{
							fontSize: { xs: "1rem", sm: "1.5rem" },
							fontWeight: 300,
							mb: 4,
						}}
					>
						Discover how Pillars AI has transformed challenges into
						opportunities for our clients, delivering impactful AI solutions
						that drive measurable success.
					</Typography>
					<Box
						sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
					>
						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{
								fontWeight: "bold",
							}}
						>
							Explore Our Projects
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Box
				display="flex"
				minHeight={800}
				flexDirection={"column"}
				padding={{ xs: 2, sm: 4 }}
			>
				<Typography
					sx={{
						fontSize: {
							xs: "1.5rem",
							sm: "2rem",
							fontWeight: 800,
							color: titleColor,
							textAlign: "center",
							width: "100%",
						},
					}}
				>
					Portfolio
				</Typography>

				<Box
					sx={{
						display: "flex",
						gap: 4,
						flexWrap: "wrap",
						justifyContent: { xs: "center" },
						alignContent: "flex-start",
						mx: "auto",
						p: { xs: 0, lg: 4 },
						mt: 4,
					}}
				>
					{projects.map((project) => (
						<ProjectCard
							{...project}
							handleClick={() => handleClick(project)}
						/>
					))}
				</Box>
			</Box>
			<ProjectDetailsModal
				open={openProject}
				handleClose={() => setOpenProject(false)}
				project={selectedProject}
			/>
		</Box>
	);
};

export default ProjectsPage;
