import {
	FeaturesHero,
	ReasonsToChooseSection,
	KeyFeatureCards,
	HorizontalTimeline,
} from "pages/components";
import {
	Box,
	Typography,
	Paper,
	Grid,
	Grow,
	Collapse,
	Fade,
	Slide,
	useMediaQuery,
} from "@mui/material";

//images
import herobg from "images/aiPhotos/14.jpg";
import reasonsBg from "images/aiPhotos/19.jpg";
import reasonsImage from "images/aiPhotos/19.jpg";

//themes
import { colors } from "@mui/material";
import { titleColor, bodyColor } from "theme/constants";
import { useTheme } from "@mui/material";
//icopns
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import TuneIcon from "@mui/icons-material/Tune";
import KeyIcon from "@mui/icons-material/Key";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
//react imports
import { useState, useEffect, useRef } from "react";

//DATA
const heroContent = {
	title: "Private LLM Deployment",
	subtitle: "AI Power—Your Data, Your Control",
	bgImage: herobg,
	content:
		"Pillars AI enables you to deploy advanced Large Language Models (LLMs) securely on-premises or in private cloud environments. Keep your data private, maintain compliance, and customize models to meet your unique business needs.",
};
const reasonsToChooseContent = [
	{
		title: " Data Privacy & Security:",
		subtitle:
			"Your data never leaves your environment, ensuring compliance GDPR, HIPAA, CCPA).",
	},
	{
		title: "Custom-Tuned Models:",
		subtitle:
			"ine-tune models for your domain, ensuring relevance and precision. Full Control: Deploy on your terms—on-premises or in private cloud platforms like AWS, Azure, or GCP.",
	},
	{
		title: " Scalable Performance",
		subtitle:
			"Handle enterprise-scale workloads with optimized efficiency and minimal latency.",
	},
];
const keyFeaturesContent = [
	{
		title: "On-Premises/Private Cloud",
		subtitle: "Integrate within your infrastructure seamlessly.",
		icon: <FilterDramaIcon fontSize="large" />,
		color: colors.blue[500],
	},
	{
		title: "Custom Fine-Tuning",
		subtitle: "Train models to align with your industry needs.",
		icon: <TuneIcon fontSize="large" />,
		color: colors.amber[500],
	},
	{
		title: "Secure API Integration",
		subtitle: "Connect with applications via private, flexible APIs.",
		icon: <KeyIcon fontSize="large" />,
		color: colors.green[500],
	},
	{
		title: "Auditability",
		subtitle: "Ensure transparency and compliance with built-in traceability.",
		icon: <VerifiedUserIcon fontSize="large" />,
		color: colors.purple[500],
	},
];

const useCasescontent = [
	{
		title: "Enterprise AI Assistants",
		description: "Secure, private tools for internal knowledge and workflows.",
	},
	{
		title: "Compliance-Driven Sectors",
		description:
			"Finance, healthcare, and government requiring strict data controls.",
	},
	{
		title: "Sensitive Data Processing",
		description: "Safely analyze confidential documents and communications.",
	},
	{
		title: " Custom AI Solutions ",
		description:
			" Develop proprietary tools while retaining full IP ownership.",
	},
];

const content = {
	title: "How it works",
	steps: [
		{
			title: "Assessment",
			description: "We evaluate your infrastructure and goals.",
			icon: <AssessmentIcon />,
			color: "rgb(237, 214, 248)",
		},
		{
			title: "Deployment",
			description: "Implement the LLM securely in your chosen environment.",
			icon: <FlightTakeoffIcon />,
			color: "rgb(224, 185, 242)",
		},
		{
			title: "Customization",
			description: "Fine-tune models for optimal performance.",
			icon: <TuneIcon />,
			color: "rgb(209, 156, 236)",
		},
		{
			title: "Integration & Support",
			description:
				"Seamlessly integrate with ongoing optimization and assistance.",
			icon: <HelpCenterIcon />,
			color: "rgb(194, 127, 230)",
		},
	],
};

//USE CASES SECTIONS >>>>>>>>>>>>>>>>
const UseCases = ({ useCases }) => {
	const theme = useTheme();

	// Track visibility of the section
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	// Track active index for sequential animation
	const [activeIndex, setActiveIndex] = useState(-1);

	useEffect(() => {
		let timeoutId;

		if (isVisible) {
			timeoutId = setTimeout(() => {
				setActiveIndex((prevIndex) => {
					if (prevIndex < useCases.length - 1) {
						return prevIndex + 1;
					}
					return prevIndex;
				});
			}, 500); // Delay between slides
		}

		return () => clearTimeout(timeoutId);
	}, [isVisible, activeIndex, useCases.length]);

	return (
		<Grid
			container
			sx={{
				padding: { xs: 2, sm: 4 },
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				gap: 2,
				m: -1,
				minHeight: { xs: 500, sm: 800 },
			}}
			ref={ref}
		>
			<Grid
				item
				xs={12}
				display="flex"
			>
				<Typography
					variant="h3"
					sx={{
						fontSize: { xs: "1.25rem", sm: "1.5rem" },
						fontWeight: 800,
						color: titleColor,
						textAlign: "center",
					}}
				>
					Ideal Use Cases
				</Typography>
			</Grid>

			{useCases.map((useCase, i) => (
				<Slide
					in={isVisible && i <= activeIndex}
					direction="right"
					timeout={500}
					key={i}
				>
					<Grid
						item
						xs={12}
						sx={{
							padding: 2,
							display: "flex",
							flexDirection: "column",
							gap: 2,
							flexGrow: 1,
							width: "100%",
						}}
					>
						<Typography
							variant="h4"
							sx={{
								color: bodyColor,
								fontSize: { xs: "1rem", sm: "1.25rem" },
								fontWeight: 800,
								width: "100%",
							}}
						>
							{useCase.title}
						</Typography>
						<Typography
							sx={{
								fontSize: { xs: ".875rem", sm: "1rem" },
								marginLeft: 2,
								width: "100%",
							}}
						>
							{useCase.description}
						</Typography>
					</Grid>
				</Slide>
			))}
		</Grid>
	);
};

//End of USE CASES SECTIONS >>>>>>>>>>>>>>>>

//End of TIMELINE >>>>>>>>>>>>

//PAGE
const PrivateDeploymentPage = () => {
	return (
		<Grid>
			<FeaturesHero {...heroContent} />
			<Box
				display="flex"
				justifyContent={"space-around"}
				alignItems={"center"}
			>
				<Box mt={{ xs: 4, sm: 0 }}>
					<UseCases useCases={useCasescontent} />
				</Box>
				<Box
					display={{ xs: "none", md: "flex" }}
					justifyContent="center"
					alignItems="center"
					position="relative"
				>
					<img
						src={reasonsImage}
						alt="hero"
						style={{ width: 600, height: 600, transform: "scaleX(-1)" }}
						//flip image so it is facing the other way
					/>
					<Box
						className="vase"
						position="absolute"
						bottom={40}
						left={30}
						width={75}
						height={75}
						bgcolor="rgba(45,0,67)"
						borderRadius="25% 25% 15% 15%"
					></Box>
				</Box>
			</Box>

			<HorizontalTimeline content={content} />

			<KeyFeatureCards
				data={keyFeaturesContent}
				sx={{ mb: 3 }}
			/>
			<Box height={100} />
			<ReasonsToChooseSection
				title="Why Choose Private LLM Deployment?"
				reasons={reasonsToChooseContent}
				bgImage={reasonsBg}
			/>
		</Grid>
	);
};
export default PrivateDeploymentPage;
