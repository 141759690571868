import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const FeaturesHero = ({ title, subtitle, content, bgImage }) => {
	const theme = useTheme();
	// State to trigger animations
	const [animate, setAnimate] = useState(false);

	// Trigger animation when the component is mounted
	useEffect(() => {
		const timer = setTimeout(() => setAnimate(true), 200); // Delay for smooth entry
		return () => clearTimeout(timer);
	}, []);
	return (
		<Box
			sx={{
				minHeight: "100vh", // Set height for the example
				position: "relative", // Ensure pseudo-element positions correctly
				display: "flex",
				alignItems: "center",
				boxShadow: "inset 0px 0px 32px 12px rgb(16, 2, 20)",
				overflow: "hidden", // Ensures pseudo-element doesn't spill out
				"&::before": {
					content: '""', // Pseudo-element content
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundBlendMode: "multiply",
					filter: "blur(4px)", // Apply blur to the pseudo-element
					zIndex: -1, // Place it behind the children
				},
			}}
		>
			<Box
				maxWidth={600}
				py={{ xs: 0, sm: 8 }}
				px={{ xs: 4, sm: 0 }}
				mx={"auto"}
				color="white"
				sx={{
					opacity: animate ? 1 : 0, // Fade-in effect
					transform: animate ? "translateY(0)" : "translateY(20px)", // Slide-in from bottom
					transition: "opacity 1s ease, transform 1s ease", // Smooth transition
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: 4,
				}}
			>
				<Typography
					sx={{
						opacity: animate ? 1 : 0,
						transform: animate ? "translateY(0)" : "translateY(-20px)",
						transition: "opacity 1s ease, transform 1s ease",
						fontSize: { xs: "2rem", sm: "3rem" },
						color: theme.palette.secondary.main,
						fontWeight: 900,
						letterSpacing: "2px",
						textAlign: "center",
					}}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						opacity: animate ? 1 : 0,
						transform: animate ? "translateY(0)" : "translateY(-10px)",
						transition: "opacity 1s ease, transform 1s ease",
						fontSize: { xs: "1.25rem", sm: "1.5rem" },
						textAlign: "center",
					}}
				>
					{subtitle}
				</Typography>

				<Typography
					sx={{
						lineHeight: 2,
						opacity: animate ? 1 : 0, // Fade-in effect
						transform: animate ? "translateY(0)" : "translateY(10px)", // Slide-in from bottom
						transition: "opacity 1.5s ease, transform 1.5s ease", // Slightly delayed animation
						fontSize: { xs: "1rem", sm: "1.25rem" },
						textAlign: "center",
					}}
				>
					{content}
				</Typography>
				<Box
					width="100%"
					display="flex"
					justifyContent="center"
					sx={{
						opacity: animate ? 1 : 0, // Fade-in effect
						transform: animate ? "translateY(0)" : "translateY(20px)", // Slide-in from bottom
						transition: "opacity 1s ease, transform 1.5s ease", // Same timing as the button
					}}
				>
					<Link
						key={"contact"}
						to={"/contact"}
						style={{
							textDecoration: "none",
							color: "white",
						}}
					>
						<Button
							variant="contained"
							color="secondary"
						>
							INQUIRE NOW
						</Button>
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default FeaturesHero;
