import {
	Box,
	Collapse,
	Fade,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	Grow,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { titleColor } from "theme/constants";

const AnimatedStep = ({ step, isVisible, index, steps, isMobile }) => {
	const [fadeIn, setFadeIn] = useState(false);
	const [growIcon, setGrowIcon] = useState(false);
	const [collapseContent, setCollapseContent] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => setFadeIn(true), index * 300 + 100);
			setTimeout(() => setGrowIcon(true), index * 300 + 500 + 500);
			setTimeout(() => setCollapseContent(true), index * 300 + 500 + 1000);
		}
	}, [isVisible, index]);

	return (
		<Fade
			in={fadeIn}
			timeout={1000}
		>
			<Box
				display={"flex"}
				flexDirection={isMobile ? "column" : "column"}
				alignItems={"center"}
				sx={{
					position: "relative",
					minHeight: isMobile ? "200px" : "250px",
					width: isMobile ? "100%" : "auto",
				}}
			>
				{/* Icon */}
				<Grow
					in={growIcon}
					timeout={1100}
				>
					<Box
						className={"icon"}
						sx={{
							background: step.color,
							color: "white",
							width: isMobile ? "40px" : "50px",
							height: isMobile ? "40px" : "50px",
							borderRadius: "50%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginBottom: isMobile ? "10px" : "20px",
							position: "absolute",
							top: 0,
						}}
					>
						{step.icon}
					</Box>
				</Grow>

				{/* Vertical Line (Top) */}
				<Box
					className={"verticalLine"}
					sx={{
						width: 2,
						bgcolor: step.color,
						position: "absolute",
						top: isMobile ? "40px" : "50px",
						height: isMobile ? "80px" : "100px",
						transform: growIcon ? "scaleY(1)" : "scaleY(0)",
						transformOrigin: "bottom",
						transition: "transform 0.5s ease",
					}}
				></Box>

				{/* Title with Arrows */}
				<Box
					sx={{
						background: step.color,
						textAlign: "center",
						objectFit: " fit-content",
						minWidth: isMobile ? "150px" : "250px",
						height: isMobile ? "40px" : "54px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
						marginTop: isMobile ? "100px" : "120px",
						"&:before": {
							content: '""',
							position: "absolute",
							right: isMobile ? "-15px" : "-20px",
							bottom: 0,
							width: 0,
							height: isMobile ? "40px" : "54px",
							borderLeft: `20px solid ${step.color}`,
							borderTop: isMobile
								? "20px solid transparent"
								: "27px solid transparent",
							borderBottom: isMobile
								? "20px solid transparent"
								: "27px solid transparent",
						},
						"&:after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: 0,
							width: 0,
							height: isMobile ? "40px" : "54px",
							borderLeft:
								steps[index - 1] && `20px solid ${steps[index - 1].color}`,
							borderTop: isMobile
								? "20px solid transparent"
								: "27px solid transparent",
							borderBottom: isMobile
								? "20px solid transparent"
								: "27px solid transparent",
							zIndex: 1,
						},
					}}
				>
					<Typography fontSize={isMobile ? "0.9rem" : "1rem"}>
						{step.title}
					</Typography>
				</Box>

				{/* Vertical Line (Bottom) */}
				<Box
					className={"verticalLine"}
					sx={{
						width: 2,
						height: isMobile ? "40px" : "50px",
						bgcolor: step.color,
						position: "absolute",
						top: isMobile ? "140px" : "174px",
						transform: collapseContent ? "scaleY(1)" : "scaleY(0)",
						transformOrigin: "top",
						transition: "transform 0.5s ease",
					}}
				></Box>

				{/* Content */}
				<Collapse
					in={collapseContent}
					timeout={500}
				>
					<Box
						component={Paper}
						sx={{
							maxWidth: isMobile ? "120px" : "150px",
							textAlign: "center",
							padding: isMobile ? 1 : 2,
							borderRadius: 2,
							marginTop: isMobile ? "30px" : "50px",
						}}
					>
						<Typography fontSize={isMobile ? "0.8rem" : "1rem"}>
							{step.description}
						</Typography>
					</Box>
				</Collapse>
			</Box>
		</Fade>
	);
};
const MobileAnimatedStep = ({ step, isVisible, index, steps, isMobile }) => {
	const [fadeIn, setFadeIn] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => setFadeIn(true), index * 300 + 100);
		}
	}, [isVisible, index]);

	return (
		<Grow
			in={fadeIn}
			timeout={1000}
		>
			<Grid
				container
				display={"flex"}
				alignItems={"center"}
				sx={{
					position: "relative",
					px: 2,
				}}
			>
				{/* title */}
				<Grid
					item
					xs={4}
					sx={{ background: step.color, borderRadius: 2, p: 1 }}
				>
					<Typography
						sx={{
							color: "white",
							fontWeight: 800,
							textAlign: "center",
							fontSize: ".875rem",
						}}
					>
						{step.title}
					</Typography>
				</Grid>

				{/* line */}
				<Grid
					item
					xs={1}
					sx={{ background: step.color, height: 2 }}
				></Grid>

				{/* icon */}
				<Grid
					item
					xs={1}
					sx={{
						background: step.color,
						color: "white",
						width: "40px",
						height: "40px",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{step.icon}
				</Grid>

				{/* line */}
				<Grid
					item
					xs={1}
					sx={{ background: step.color, height: 2 }}
				></Grid>

				{/* Content */}
				<Grid
					item
					xs={5}
				>
					<Box
						component={Paper}
						sx={{
							textAlign: "center",
							padding: isMobile ? 1 : 2,
							borderRadius: 2,
						}}
					>
						<Typography fontSize={isMobile ? "0.8rem" : "1rem"}>
							{step.description}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Grow>
	);
};

const HorizontalTimeline = ({ content }) => {
	const isMobile = useMediaQuery("(max-width:850px)");

	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{ threshold: 0.5 }
		);

		if (ref.current) observer.observe(ref.current);
		return () => observer.disconnect();
	}, []);

	return (
		<Box
			minHeight={600}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={6}
			mb={6}
		>
			<Typography
				sx={{
					color: titleColor,
					fontWeight: 800,
					textAlign: "center",
					fontSize: { xs: "1.25rem", sm: "1.5rem" },
					width: "100%",
				}}
			>
				{content.title}
			</Typography>
			{/* horizontal for medium screens and up, vertical for phones */}

			<Box
				sx={{
					display: "flex",
					width: "100%",
					maxWidth: 800,
					justifyContent: "center",
					alignItems: "center",
					gap: 4,
				}}
			></Box>
			<Box
				ref={ref}
				display="flex"
				flexDirection={isMobile ? "column" : "row"}
				mx="auto"
				alignItems={isMobile ? "center" : "flex-start"}
				gap={isMobile ? 4 : 0}
				overflow="scroll"
				maxWidth={"100%"}
				p={4}
			>
				{content.steps.map((step, i) =>
					isMobile ? (
						<MobileAnimatedStep
							key={i}
							step={step}
							index={i}
							steps={content.steps}
							isVisible={isVisible}
							isMobile={isMobile}
						/>
					) : (
						<AnimatedStep
							key={i}
							step={step}
							index={i}
							steps={content.steps}
							isVisible={isVisible}
							isMobile={isMobile}
						/>
					)
				)}
			</Box>
		</Box>
	);
};

export default HorizontalTimeline;
