import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/16.jpg";
import { ReasonsToChooseSection, KeyFeatureCards, HorizontalTimeline } from "pages/components";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SoapIcon from '@mui/icons-material/Soap';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const heroContent = {
	title: "Data Analysis & Insights",
	subtitle: "Transform Data into Actionable Intelligence",
	content: `At Pillars AI, we help organizations unlock the full potential of their data. Our Data Analysis & Insights services turn raw information into meaningful, actionable insights that drive smarter decisions and better outcomes.`,
	bgImage: heroBackgroundImage,
};
const reasonsToChoosePillarsAIForDataAnalysis = {
	title: "Why Pillars AI for Data Analysis?",
	reasons: [
		{
			title: "Advanced Analytics",
			subtitle:
				"Leverage cutting-edge AI techniques to uncover trends and patterns.",
		},
		{
			title: "Custom Insights",
			subtitle: "Gain actionable intelligence tailored to your business needs.",
		},
		{
			title: "End-to-End Solutions",
			subtitle: "From data preparation to visualization, we handle it all.",
		},
		{
			title: "Scalable Results",
			subtitle: "Deliver insights that grow with your organization.",
		},
	],
	bgImage: heroBackgroundImage,
};
const keyOfferings = [
    {
      title: "Predictive Analytics",
      subtitle: "Forecast trends and outcomes for proactive decision-making.",
      color: "rgb(80, 182, 255)"
    },
    {
      title: "Customer Insights",
      subtitle: "Understand behavior, preferences, and needs at a deeper level.",
      color: "rgb(255, 85, 85)"
    },
    {
      title: "Operational Analytics",
      subtitle: "Identify inefficiencies and optimize workflows.",
      color: "rgb(225, 211, 17)"
    },
    {
      title: "Real-Time Dashboards",
      subtitle: "Visualize critical data in an intuitive, real-time format.",
      color: "rgb(186, 92, 254)"
    }
  ];

  const timelineContent = {
	title: "Our Development Process",
	steps: [
		{
			title: "Data Discovery",
			description:
				"Assess your data sources, quality, and potential.",
			icon: <TroubleshootIcon />,
			color: "rgb(142, 220, 147)",
		},
		{
			title: "Preparation & Cleaning",
			description:
				"Ensure data accuracy with thorough cleansing and preprocessing.",
			icon: <SoapIcon />,
			color: "rgb(99, 202, 106)",
		},
		{
			title: "Advanced Analytics",
			description: "Apply AI techniques like machine learning and predictive modeling.",
			icon: <AnalyticsIcon />,
			color: "rgb(66, 182, 74)",
		},
		{
			title: "Visualization & Reporting",
			description:
				"Deliver clear, interactive dashboards and reports for decision-making.",
			icon: <DeveloperBoardIcon />,
			color: "rgb(48, 159, 56)",
		},
		{
			title: "Ongoing Optimization",
			description:
				"Continuously refine models and insights to adapt to evolving needs.",
			icon: <AutoAwesomeIcon />,
			color: "rgb(19, 133, 27)",
		},
	],
};

const DataAnalysisPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
            <KeyFeatureCards data={keyOfferings} />
			<HorizontalTimeline content={timelineContent} />
			<ReasonsToChooseSection {...reasonsToChoosePillarsAIForDataAnalysis} />
		</div>
	);
};
export default DataAnalysisPage;
