import FullScreenHeroWithSubscriptionForm from "../components/FullScreenHeroWithSubscriptionForm";
import FeatureCardsWithColorfullIconsAndLearnMoreLink from "../components/FeatureCardsWithColorfullIconsAndLearnMoreLink";
import WithSimpleCards from "../components/WithSimpleCards";
import { useTheme } from "@mui/material";
import { Box, Container, Typography } from "@mui/material";
import { bodyColor, titleColor, darkGradient } from "theme/constants";
import computerBg from "images/aiPhotos/38.png";
const Home = () => {
	const theme = useTheme();

	return (
		<div>
			<FullScreenHeroWithSubscriptionForm sx={{ zIndex: 999 }} />

			<Box
				sx={{
					display: "flex",
					minHeight: "80vh",
					alignItems: "center",
					justifyContent: "space-between",
					background: darkGradient,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "right",
					paddingLeft: { xs: "2em", lg: "10rem" },
					paddingRight: { xs: "2em", lg: "0rem" },
					flexDirection: "column",
					py: 6,
				}}
			>
				<Typography
					variant="h3"
					sx={{
						color: theme.palette.secondary.main,
						fontSize: "2rem",
						fontWeight: 800,
						width: "100%",
						textAlign: "left",
						mb: 4,
					}}
				>
					About Us
				</Typography>

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box sx={{ maxWidth: { xs: "100%", lg: "50%" } }}>
						<Typography
							sx={{
								fontSize: "1rem",
								fontWeight: 800,
								color: "white",
								marginBottom: 4,
								lineHeight: 2,
							}}
						>
							At Pillar1, we are passionate about leveraging cutting-edge AI and
							IT solutions to tackle complex challenges and deliver innovative
							results. Our team comprises highly skilled professionals with
							extensive experience in the AI landscape, dating back to when
							“machine learning” first emerged as a transformative concept.
							Whether you require a comprehensive end-to-end solution—from
							initial ideation to full deployment—or support with a specific
							aspect such as refining requirements, developing models, or
							prototyping, we are equipped to meet your needs with precision and
							expertise.
						</Typography>
						<Typography
							sx={{
								fontSize: "1rem",
								fontWeight: 800,
								color: "white",
								marginBottom: 2,
								lineHeight: 2,
							}}
						>
							Our approach is built on a commitment to excellence and
							collaboration. We design, develop, deploy, and optimize AI
							solutions to empower our clients to achieve their goals
							seamlessly. Our portfolio includes impactful projects such as
							ISS-AI (AI-assisted social case management), Path-finder (digital
							archive and OCR-driven data extraction), and Document
							Comprehension (an advanced PDF annotation system). These
							initiatives highlight our ability to drive innovation across
							diverse industries, from exploratory proofs-of-concept to
							transformative, large-scale implementations. At Pillar1, we thrive
							on working closely with our clients to bring their boldest ideas
							to life, always embracing the opportunity to shape the future of
							technology with professionalism, creativity, and a shared vision
							for success.
						</Typography>
					</Box>
					<Box
						sx={{
							width: 600,
							minHeight: 600,
							backgroundImage: `url(${computerBg})`, // Corrected property name
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
							transform: "scaleX(-1)",
							display: { xs: "none", lg: "flex" },
							animation: "float 4s ease-in-out infinite", // Apply animation
							"@keyframes float": {
								"0%": {
									transform: "translateY(0) scaleX(-1)",
								},
								"50%": {
									transform: "translateY(-20px) scaleX(-1)", // Float up
								},
								"100%": {
									transform: "translateY(0) scaleX(-1)",
								},
							},
						}}
					/>
				</Box>
			</Box>

			<FeatureCardsWithColorfullIconsAndLearnMoreLink />
			<Box
				sx={{
					backgroundColor: "rgb(17, 0, 31)",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<WithSimpleCards />
			</Box>
		</div>
	);
};

export default Home;
