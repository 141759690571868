import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { titleColor } from "theme/constants";

const NextArrow = ({ onClick }) => (
	<Box
		onClick={onClick}
		sx={{
			position: "absolute",
			top: "50%",
			right: { xs: "10px", md: "20px" },
			transform: "translateY(-50%)",
			zIndex: 10,
			cursor: "pointer",
			color: "white",
			fontSize: "2rem",
		}}
	>
		<ArrowForwardIosIcon sx={{ fontSize: { xs: "1em", md: "1.5em" } }} />
	</Box>
);

const PrevArrow = ({ onClick }) => (
	<Box
		onClick={onClick}
		sx={{
			position: "absolute",
			top: "50%",
			left: { xs: "10px", md: "20px" },
			transform: "translateY(-50%)",
			zIndex: 10,
			cursor: "pointer",
			color: "white",
			fontSize: "2rem",
		}}
	>
		<ArrowBackIosNewIcon sx={{ fontSize: { xs: "1em", md: "1.5em" } }} />
	</Box>
);

const CarouselCard = ({ index, keyword, description, image }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				justifyContent: "space-between",
				gap: 4,
				p: { xs: 2, md: 6 },
				textAlign: { xs: "center", md: "left" },
			}}
		>
			{/* Text Section */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: { xs: "center", md: "flex-start" },
					alignItems: { xs: "center", md: "flex-start" },
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontWeight: 900,
						fontSize: "3rem",
						color: "white",
						textAlign: { xs: "center", md: "left" },
					}}
				>
					0{index + 1}
				</Typography>
				<Typography
					sx={{
						fontWeight: "bold",
						textAlign: { xs: "center", md: "left" },
						mb: 1,
						fontSize: { xs: "1.5rem", md: "2rem" },
						color: theme.palette.primary.dark,
						textShadow: `-1px -1px 0 white,
                        1px -1px 0 white,
                        -1px  1px 0 white,
                        1px  1px 0 white`,
					}}
				>
					{keyword}
				</Typography>
				<Typography
					sx={{
						color: "white",
						textAlign: { xs: "center", md: "left" },
						fontSize: { xs: "1rem", md: "1.5rem" },
					}}
				>
					{description}
				</Typography>
			</Box>

			{/* Image Section */}
			<Box
				sx={{
					flex: { xs: "0 0 100%", md: "0 0 50%" },
					display: { xs: "none", md: "flex" },
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<Box
					component="img"
					src={image}
					alt={keyword}
					sx={{
						maxWidth: "100%",
						maxHeight: 300,
						objectFit: "contain",
						borderRadius: 2,
						boxShadow: 2,
					}}
				/>
			</Box>
		</Box>
	);
};

const CarouselInfoSection = ({ title, items }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 4,
				p: 4,
			}}
		>
			{/* Title */}
			{title && (
				<Typography
					variant="h4"
					sx={{
						fontWeight: "bold",
						textAlign: "center",
						mb: 2,
						color: titleColor,
					}}
				>
					{title}
				</Typography>
			)}

			{/* Carousel */}
			<Slider
				{...settings}
				style={{
					width: "80%",
					backgroundColor: theme.palette.primary.dark,
					borderRadius: "14px",
					position: "relative",
					padding: "0px 40px",
				}}
			>
				{items.map((item, index) => (
					<CarouselCard
						key={index}
						{...item}
						index={index}
					/>
				))}
			</Slider>
		</Box>
	);
};

CarouselInfoSection.propTypes = {
	title: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			keyword: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			image: PropTypes.string.isRequired,
		})
	).isRequired,
};

CarouselInfoSection.defaultProps = {
	title: "",
};

export default CarouselInfoSection;
