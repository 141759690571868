import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/15.jpg";
import {
	ReasonsToChooseSection,
	KeyFeatureCards,
	HorizontalTimeline,
} from "pages/components";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import TuneIcon from "@mui/icons-material/Tune";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

const heroContent = {
	title: "AI Product Development",
	subtitle: "From Idea to Impactful AI Products",
	content: `At Pillars AI, we specialize in transforming your ideas into innovative AI-driven products. Whether you’re building a new solution or enhancing existing tools, our team ensures every step of the journey is seamless, efficient, and impactful.`,
	bgImage: heroBackgroundImage,
};
const reasonsToChoosePillarsAIContent = {
	title: "Why Choose Pillars AI?",
	reasons: [
		{
			title: "Expert Guidance",
			subtitle: "Leverage our expertise in cutting-edge AI technologies.",
		},
		{
			title: "Tailored Solutions",
			subtitle: "Design products that meet your specific business needs.",
		},
		{
			title: "Scalable Development",
			subtitle: "Build products ready to grow with your organization.",
		},
		{
			title: "End-to-End Support",
			subtitle: "From concept to deployment, we’ve got you covered.",
		},
	],
	bgImage: heroBackgroundImage,
};
const keyOfferings = [
	{
		title: "Custom AI Solutions",
		subtitle: "Build AI products tailored to your industry and goals.",
		color: "rgb(80, 182, 255)",
	},
	{
		title: "Integration Services",
		subtitle: "Embed AI seamlessly into existing tools and workflows.",
		color: "rgb(255, 85, 85)",
	},
	{
		title: "User-Centric Design",
		subtitle: "Ensure intuitive and impactful user experiences.",
		color: "rgb(225, 211, 17)",
	},
	{
		title: "Maintenance & Support",
		subtitle: "Provide ongoing updates and enhancements.",
		color: "rgb(186, 92, 254)",
	},
];

const timelineContent = {
	title: "Our Development Process",
	steps: [
		{
			title: "Discovery & Ideation",
			description:
				"Collaborate to define your product’s goals, features, and AI capabilities.",
			icon: <AssessmentIcon />,
			color: "rgb(241, 211, 177)",
		},
		{
			title: "Feasibility Analysis",
			description:
				"Validate ideas with technical assessments and proof of concept (PoC).",
			icon: <FlightTakeoffIcon />,
			color: "rgb(239, 195, 144)",
		},
		{
			title: "Design & Prototyping",
			description: "Build user-centric designs and functional prototypes.",
			icon: <TuneIcon />,
			color: "rgb(244, 182, 112)",
		},
		{
			title: "Development & Testing",
			description:
				"Develop robust AI models and rigorously test for performance.",
			icon: <HelpCenterIcon />,
			color: "rgb(243, 170, 88)",
		},
		{
			title: "Deployment & Optimization",
			description:
				"Deploy products seamlessly and refine them for scalability and efficiency.",
			icon: <HelpCenterIcon />,
			color: "rgb(244, 157, 57)",
		},
	],
};

const ProductDevelopmentPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<KeyFeatureCards data={keyOfferings} />
			<HorizontalTimeline content={timelineContent} />
			<ReasonsToChooseSection {...reasonsToChoosePillarsAIContent} />
		</div>
	);
};
export default ProductDevelopmentPage;
