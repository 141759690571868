import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { contrastOrange } from "theme/constants";
import { Fade } from "@mui/material";

const Form = () => {
	const theme = useTheme();

	return (
		<Box>
			<Box
				marginBottom={2}
				display="flex"
				flexDirection="column"
				gap={2}
			>
				<Fade
					in
					timeout={1000}
				>
					<Typography
						color={contrastOrange}
						sx={{
							fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4rem" },
							fontFamily: `"Poppins", sans-serif`,
							fontWeight: 900,
							letterSpacing: "1px",
							WebkitTextStroke: `2px ${contrastOrange}  `, // Adds an outline for extra weight
							textFillColor: contrastOrange, // Fills the text
						}}
					>
						Unlock the Power of AI with Pillar1
					</Typography>
				</Fade>
				<Fade
					in
					timeout={2000}
				>
					<Typography
						variant="h4"
						color="white"
						sx={{
							fontWeight: 600,
							fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
						}}
					>
						AI Solution Implementation Consulting and Product Development
					</Typography>
				</Fade>
			</Box>
			<Box marginBottom={3}>
				<Fade
					in
					timeout={3000}
				>
					<Typography
						variant="h6"
						component="p"
						color="white"
						sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
					>
						Pillar1 is a leading provider of AI solution implementation
						consulting and product development services. We specialize in
						leveraging the latest advancements in artificial intelligence to
						transform businesses and drive innovation. Pillar1 assists
						businesses in implementing AI solutions, achieving their strategic
						objectives, and unlocking the full potential of artificial
						intelligence.
					</Typography>
				</Fade>
			</Box>

			<Box
				display="flex"
				flexDirection={"column"}
				justifyContent={"center"}
			>
				{/*<Box marginBottom={2}>
                    <Typography variant="body1" component="p">
                        Join over 5000 subscribers for our newsletter
                    </Typography>
                </Box>*/}
				<Box
					component={"form"}
					noValidate
					autoComplete="off"
					sx={{
						"& .MuiInputBase-input.MuiOutlinedInput-input": {
							bgcolor: "background.paper",
						},
					}}
				>
					<Box
						display="flex"
						flexDirection={{ xs: "column", sm: "row" }}
						alignItems={{ xs: "stretched", sm: "flex-start" }}
					>
						<Fade
							in
							timeout={3000}
						>
							<Box
								flex={"1 1 auto"}
								component={TextField}
								label="Enter your email"
								variant="filled"
								sx={{backgroundColor: "white", borderRadius: 1}}
								fullWidth
								height={54}
							/>
						</Fade>
						<Fade
							in
							timeout={3000}
						>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								sx={{
									height: 54,
									marginTop: { xs: 2, sm: 0 },
									marginLeft: { sm: 2 },
								}}
							>
								Subscribe
							</Button>
						</Fade>
					</Box>
				</Box>
			</Box>
			<Box
				marginTop={{ xs: 4, sm: 6, md: 8 }}
				textAlign={"left"}
			>
				{/*<Typography
                    variant="body1"
                    component="p"
                    color="text.primary"
                    sx={{textTransform: 'uppercase'}}
                >
                    Trusted by industry leading companies
                </Typography>

                <Box display="flex" flexWrap="wrap" justifyContent={'flex-start'}>
                    {mock.map((item, i) => (
                        <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                            <Box
                                component="img"
                                height={1}
                                width={1}
                                src={item}
                                alt="..."
                                sx={{
                                    filter:
                                        theme.palette.mode === 'dark'
                                            ? 'brightness(0) invert(0.7)'
                                            : 'none',
                                }}
                            />
                        </Box>
                    ))}
                </Box>*/}
			</Box>
		</Box>
	);
};

export default Form;
