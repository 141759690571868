/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import { colors } from "@mui/material";

import Container from "../Container";
import { Link } from "react-router-dom";
import {
	purpleMain,
	purpleWhite,
	contrastOrange,
	bodyColor,
	titleColor,
} from "../../theme/constants";
import { useState, useRef, useEffect } from "react";
import { Zoom } from "@mui/material";
import { useTheme } from "@mui/material";

const mock = [
	{
		color: colors.lightBlue[500],
		title: "Automated AI Solutions",
		subtitle:
			"Streamlined solutions optimizing workflows and driving efficiency.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
				/>
			</svg>
		),
		path: "/automated-ai-solutions",
	},
	{
		color: colors.purple[500],
		title: "Private LLM Deployment",
		subtitle: "Deploy private LLMs with data privacy and seamless integration.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
				/>
			</svg>
		),
		path: "/private-deployment",
	},
	{
		color: colors.yellow[500],
		title: "AI Strategy Consulting",
		subtitle: "Informed guidance on AI developments and decision-making.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
				/>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
				/>
			</svg>
		),
		path: "strategy-consulting",
	},
	{
		color: colors.indigo[500],
		title: "End-to-End AI Architecture",
		subtitle:
			"Comprehensive architecture reflecting priorities from data to maintenance.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
				/>
			</svg>
		),
		path: "/end-to-end-architecture",
	},
	{
		color: colors.red[500],
		title: "Collaboration with Teams",
		subtitle: "Empower teams in leveraging AI with support and guidance.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
				/>
			</svg>
		),
		path: "/collaboration",
	},
	{
		color: colors.green[500],

		title: "AI Product Development",
		subtitle: "Transform ideas into scalable AI products.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
				/>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
				/>
			</svg>
		),
		path: "/product-development",
	},
	{
		color: colors.orange[500],
		title: "Data Analysis & Insights",
		subtitle: "Extract valuable insights from data-driven analysis.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		),
		path: "/data-analysis",
	},
	{
		color: colors.deepPurple[500],

		title: "Experienced AI Engineers",
		subtitle: "Deliver scalable AI products with expertise.",
		icon: (
			<svg
				height={48}
				width={48}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
				/>
			</svg>
		),
		path: "/AiEngineers",
	},
];

const FeatureCardsWithColorfullIconsAndLearnMoreLink = () => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: [0.1, 0.5] }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);
	return (
		<Box
			sx={{
				background: `linear-gradient(-135deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)`,
				minHeight: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Container>
				<Grid
					container
					//horizontal and vertical spacing
					spacing={4}
					ref={ref}
				>
					<Grid item xs={12}>
						<Box textAlign={"center"}>
							<Typography
								variant={"h4"}
								gutterBottom
								sx={{
									fontWeight: 700,
									mb: 2,
									color: titleColor,
								}}
							>
								Our Services
							</Typography>
							<Typography
								variant={"h6"}
								sx={{
									fontWeight: 400,
									color: bodyColor,
								}}
							>
								We provide a range of services to help you achieve your
								AI goals.
							</Typography>
						</Box>
					</Grid>
					
					{mock.map((item, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							key={i}
						>
							<Link
								to={item.path}
								key={i}
								style={{ textDecoration: "none" }}
							>
								<Zoom
									in={isVisible}
									easing={"ease-in-out"}
									timeout={500} // Fixed duration for all animations
									style={{
										transitionDelay: `${i * 150}ms`, // Staggered delay for each card
									}}
								>
									<Box
										display={"block"}
										width={1}
										height={1}
										sx={{
											textDecoration: "none",
											transition: "all .2s ease-in-out",
											"&:hover": {
												transform: "translateY(-4px)",
											},
											
										}}
									>
										<Box
											component={Card}
											padding={4}
											borderRadius={2}
											width={1}
											height={1}
											
											sx={{
												transition: "all .2s ease-in-out",
												background: `linear-gradient(-135deg, ${alpha(item.color, 0.1)} 0%, rgb(255, 255, 255) 100%)`,
												"&:hover": {
													transform: `translateY(-${theme.spacing(
														1 / 2
													)}) scale(1.10)`,
												},
											}}
											color={bodyColor}
										>
											<Box
												display={"flex"}
												flexDirection={"column"}
											>
												<Box
													component={Avatar}
													width={60}
													height={60}
													marginBottom={2}
													bgcolor={alpha(item.color, 0.0)}
													color={item.color}
													variant={"rounded"}
													mx={"auto"}
												>
													{item.icon}
												</Box>
												<Typography
													variant={"h6"}
													gutterBottom
													sx={{
														fontWeight: 700,
														mx: "auto",
														textAlign: "center",
														letterSpacing: "-.03rem",
													}}
												>
													{item.title}
												</Typography>
												<Typography textAlign={"center"}>
													{item.subtitle}
												</Typography>
												<Box sx={{ flexGrow: 1 }} />
											</Box>
										</Box>
									</Box>
								</Zoom>
							</Link>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default FeatureCardsWithColorfullIconsAndLearnMoreLink;
