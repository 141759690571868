 const light = {
	alternate: {
		main: "#F4F0FA", // Light version of purple white
		dark: "#EDE6F6", // Slightly darker than purple white
	},
	cardShadow: "rgba(48, 35, 91, .15)", // Based on purpleMain
	mode: "light",
	primary: {
		main: "#30235B", // purpleMain
		light: "#52477A", // Lighter shade of purpleMain
		dark: "#1D173D", // Darker shade of purpleMain
		contrastText: "#fff", // White for contrast
	},
	secondary: {
		main: "rgb(188, 71, 36)", // contrastOrange
		light: "rgb(211, 111, 70)", // Lighter shade of orange
		dark: "rgb(138, 47, 24)", // Darker shade of orange
		contrastText: "#fff", // White for contrast
	},
	text: {
		primary: "#1e2022", // Dark neutral for text
		secondary: "#4A4B52", // Slightly lighter neutral
	},
	divider: "rgba(0, 0, 0, 0.12)", // Subtle divider
	background: {
		paper: "#ffffff", // White for light mode paper
		default: "#F8F6FB", // Very light purple white for default background
		level2: "#F4F0FA", // Matches alternate main
		level1: "#ffffff", // Standard white
	},
};

 const dark = {
	alternate: {
		main: "#2A1F4B", // Dark muted purple
		dark: "#20163A", // Deeper dark purple
	},
	cardShadow: "rgba(0, 0, 0, .25)", // Standard shadow
	common: {
		black: "#000",
		white: "#fff",
	},
	mode: "dark",
	primary: {
		main: "#30235B", // purpleMain
		light: "#52477A", // Lighter shade of purpleMain
		dark: "#1D173D", // Darker shade of purpleMain
		contrastText: "#fff", // White for contrast
	},
	secondary: {
		main: "rgb(188, 71, 36)", // contrastOrange
		light: "rgb(211, 111, 70)", // Lighter shade of orange
		dark: "rgb(138, 47, 24)", // Darker shade of orange
		contrastText: "#fff", // White for contrast
	},
	text: {
		primary: "#EEEEEF", // Light neutral for text
		secondary: "#AEB0B4", // Muted secondary text color
	},
	divider: "rgba(255, 255, 255, 0.12)", // Subtle divider
	background: {
		paper: "#2D2540", // Dark purple as paper background
		default: "#1F172B", // Dark background, muted purple
		level2: "#272038", // Slightly lighter than default
		level1: "#30235B", // Main purple for elements
	},
};

export { light, dark };
